import {
    CatalogApiGetCatalogItemRequest,
    CatalogApiSearchCatalogItemsRequest,
    Item,
} from "@scaleleap/selling-partner-api-sdk/lib/api-models/catalog-items-api-model-v20220401";
import {
    Box,
    CancelInboundPlanResponse,
    ConfirmPackingOptionResponse,
    ConfirmTransportationOptionsResponse,
    CreateInboundPlanRequest,
    CreateInboundPlanResponse,
    FbaInboundApiConfirmTransportationOptionsRequest,
    FbaInboundApiGeneratePlacementOptionsRequest,
    FbaInboundApiGenerateTransportationOptionsRequest,
    FbaInboundApiGetInboundOperationStatusRequest,
    FbaInboundApiGetInboundPlanRequest,
    FbaInboundApiSetPackingInformationRequest,
    GeneratePlacementOptionsResponse,
    GenerateTransportationOptionsResponse,
    InboundOperationStatus,
    InboundPlan,
    ListPlacementOptionsResponse,
    PackingOption,
    PlacementOption,
    SetPackingInformationResponse,
    SetPrepDetailsRequest,
    SetPrepDetailsResponse,
    Shipment,
    Item as ShipmentItem,
    TransportationOption,
} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model-v20240320";
import {
    CommonTransportResult,
    CreateInboundShipmentPlanRequest,
    CreateInboundShipmentPlanResult,
    FbaInboundApiCreateInboundShipmentRequest,
    FbaInboundApiGetShipmentsRequest,
    FbaInboundApiPutTransportDetailsRequest,
    FbaInboundApiUpdateInboundShipmentRequest,
    GetTransportDetailsResult,
    InboundShipmentPlan,
    InboundShipmentResponse,
    LabelPrepPreference,
    ShipmentStatus,
    GetShipmentsResult,
} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model";
import {makeCall, RequestExtraParams} from "./common";
import {
    InboundBox,
    InboundBoxV2,
    InboundPackingGroup,
    InboundShipment,
    InboundShipmentItem,
    InboundShipmentV2,
    WarehouseAddress,
    WarehouseContact,
    WarehouseProduct,
} from "../types/WarehouseTypes";
import {
    CreateFeedResponse,
    Feed,
    FeedsApiGetFeedDocumentRequest,
    FeedsApiGetFeedRequest,
} from "@scaleleap/selling-partner-api-sdk/lib/api-models/feeds-api-model-v20210630";

export const MARKETPLACE_ID = "ATVPDKIKX0DER";

export async function searchCatalogItems(request: CatalogApiSearchCatalogItemsRequest & RequestExtraParams): Promise<Item[]> {
    const res = await makeCall("spApi/catalogItems/searchCatalogItems", {...request, devMode: false});

    return res.result || [];
}

export async function getCatalogItem(request: CatalogApiGetCatalogItemRequest & RequestExtraParams): Promise<Item> {
    const res = await makeCall("spApi/catalogItems/getCatalogItem", {...request, devMode: false});

    return res.result;
}

// export async function createInboundPlan(request: CreateInboundPlanRequest & RequestExtraParams): Promise<CreateInboundPlanResponse> {
//     const res = await makeCall("spApi/fulfillmentInbound2024/createInboundPlan", {}, "POST", request);

//     return res.result;
// }

export async function getInboundOperationStatus(
    request: FbaInboundApiGetInboundOperationStatusRequest & RequestExtraParams
): Promise<InboundOperationStatus> {
    const res = await makeCall("spApi/fulfillmentInbound2024/getInboundOperationStatus", request);
    return res.result;
}

export async function getInboundPlan(request: FbaInboundApiGetInboundPlanRequest & RequestExtraParams): Promise<InboundPlan> {
    const res = await makeCall("spApi/fulfillmentInbound2024/getInboundPlan", request);
    return res.result;
}

export async function generatePlacementOptions(
    request: FbaInboundApiGeneratePlacementOptionsRequest,
    devMode?: boolean
): Promise<GeneratePlacementOptionsResponse> {
    const res = await makeCall("spApi/fulfillmentInbound2024/generatePlacementOptions", {devMode}, "POST", request);
    return res.result;
}

export async function createInboundShipmentPlan(
    products: WarehouseProduct[],
    address: WarehouseAddress,
    labelPrepPreference: LabelPrepPreference = LabelPrepPreference.SellerLabel
): Promise<CreateInboundShipmentPlanResult> {
    // First check if all products have SKU
    const productsWithoutSKU = products.filter((product) => !product.sku);
    if (productsWithoutSKU.length > 0) {
        throw new Error(`The following products do not have SKU: ${productsWithoutSKU.map((product) => product.asin).join(", ")}`);
    }

    const {name, addressLine1, addressLine2, city, stateOrProvinceCode, postalCode, countryCode} = address;

    let request: CreateInboundShipmentPlanRequest = {
        InboundShipmentPlanRequestItems: products.map((product) => {
            if (!product.sku) {
                throw new Error(`Product with ASIN ${product.asin} does not have SKU`);
            }
            return {
                ASIN: product.asin,
                Condition: "NewItem",
                Quantity: product.quantity,
                SellerSKU: product.sku,
            };
        }),
        LabelPrepPreference: labelPrepPreference,
        ShipFromAddress: {
            AddressLine1: addressLine1,
            City: city,
            CountryCode: countryCode,
            Name: name,
            PostalCode: postalCode,
            StateOrProvinceCode: stateOrProvinceCode,
        },
    };

    if (addressLine2 && addressLine2.trim() !== "") {
        request.ShipFromAddress.AddressLine2 = addressLine2;
    }

    const res = await makeCall("spApi/fulfillmentInbound/createInboundShipmentPlan", {devMode: false}, "POST", request);
    return res.result;
}

export async function createInboundShipment(
    shipmentPlan: InboundShipmentPlan,
    shipmentName: string,
    labelPrepPreference: LabelPrepPreference,
    shipmentStatus: ShipmentStatus,
    address: WarehouseAddress,
    devMode?: boolean
): Promise<InboundShipmentResponse> {
    const {name, addressLine1, addressLine2, city, stateOrProvinceCode, postalCode, countryCode} = address;

    let request: FbaInboundApiCreateInboundShipmentRequest = {
        shipmentId: shipmentPlan.ShipmentId,
        body: {
            InboundShipmentHeader: {
                DestinationFulfillmentCenterId: shipmentPlan.DestinationFulfillmentCenterId,
                LabelPrepPreference: labelPrepPreference,
                ShipmentName: shipmentName,
                ShipmentStatus: shipmentStatus,
                ShipFromAddress: {
                    AddressLine1: addressLine1,
                    City: city,
                    CountryCode: countryCode,
                    Name: name,
                    PostalCode: postalCode,
                    StateOrProvinceCode: stateOrProvinceCode,
                },
                IntendedBoxContentsSource: "FEED",
            },
            MarketplaceId: MARKETPLACE_ID,
            InboundShipmentItems: shipmentPlan.Items.map((product) => ({
                SellerSKU: product.SellerSKU,
                QuantityShipped: product.Quantity,
            })),
        },
    };

    // let sandboxRequest: FbaInboundApiCreateInboundShipmentRequest = {
    //     shipmentId: 'ShipmentId',
    //     body: {
    //         InboundShipmentHeader: {
    //             ShipmentName: '43545345',
    //             ShipFromAddress: {
    //                 Name: '35435345',
    //                 AddressLine1: '123 any st',
    //                 DistrictOrCounty: 'Washtenaw',
    //                 City: 'Ann Arbor',
    //                 StateOrProvinceCode: 'Test',
    //                 CountryCode: 'US',
    //                 PostalCode: '48103',
    //             },
    //             DestinationFulfillmentCenterId: 'AEB2',
    //             AreCasesRequired: true,
    //             ShipmentStatus: 'WORKING',
    //             LabelPrepPreference: 'SELLER_LABEL',
    //             IntendedBoxContentsSource: 'NONE',
    //         },
    //         InboundShipmentItems: [
    //             {
    //                 ShipmentId: '345453',
    //                 SellerSKU: '34534545',
    //                 FulfillmentNetworkSKU: '435435435',
    //                 QuantityShipped: 0,
    //                 QuantityReceived: 0,
    //                 QuantityInCase: 0,
    //                 ReleaseDate: '2020-04-23',
    //                 PrepDetailsList: [
    //                     {
    //                         PrepInstruction: 'Polybagging',
    //                         PrepOwner: 'AMAZON',
    //                     },
    //                 ],
    //             },
    //         ],
    //         MarketplaceId: 'MarketplaceId',
    //     },
    // };

    if (addressLine2 && addressLine2.trim() !== "") {
        request.body.InboundShipmentHeader.ShipFromAddress.AddressLine2 = addressLine2;
    }

    const {body, ...queryParams} = request;
    const res = await makeCall("spApi/fulfillmentInbound/createInboundShipment", {devMode, ...queryParams}, "POST", body);

    return res.result;
}

export async function updateInboundShipment(
    request: FbaInboundApiUpdateInboundShipmentRequest & RequestExtraParams
): Promise<InboundShipmentResponse> {
    const {body, ...queryParams} = request;
    const res = await makeCall("spApi/fulfillmentInbound/updateInboundShipment", queryParams, "PUT", body);
    return res.result;
}

export async function getInboundShipments(request: FbaInboundApiGetShipmentsRequest & RequestExtraParams): Promise<GetShipmentsResult> {
    const res = await makeCall("spApi/fulfillmentInbound/getShipments", request);
    return res.result;
}

export async function submitShipmentContents(
    shipments: InboundShipment[],
    boxes: InboundBox[],
    devMode?: boolean
): Promise<CreateFeedResponse> {
    const res = await makeCall(`shipments_v2/boxing/submitContents`, {devMode}, "POST", {
        shipments,
        boxes,
    });
    return res.result;
}

export async function getFeed(request: FeedsApiGetFeedRequest & RequestExtraParams): Promise<Feed> {
    const res = await makeCall("spApi/feeds/getFeed", request);
    return res.result;
}

export async function downloadReport(request: FeedsApiGetFeedDocumentRequest & RequestExtraParams) {
    const res = await makeCall("spApi/feeds/downloadReport", request);
    return res.result;
}

export async function putTransportDetails(
    request: FbaInboundApiPutTransportDetailsRequest & RequestExtraParams
): Promise<CommonTransportResult> {
    const {body, ...queryParams} = request;
    const res = await makeCall("spApi/fulfillmentInbound/putTransportDetails", queryParams, "PUT", body);
    return res.result;
}

export async function estimateTransport(shipmentId: string, devMode?: boolean): Promise<CommonTransportResult> {
    const res = await makeCall(`spApi/fulfillmentInbound/estimateTransport`, {shipmentId, devMode}, "POST");
    return res.result;
}

export async function getTransportDetails(shipmentId: string, devMode?: boolean): Promise<GetTransportDetailsResult> {
    const res = await makeCall(`spApi/fulfillmentInbound/getTransportDetails`, {shipmentId, devMode});
    return res.result;
}

export async function confirmTransport(shipmentId: string, devMode?: boolean): Promise<CommonTransportResult> {
    const res = await makeCall(`spApi/fulfillmentInbound/confirmTransport`, {shipmentId, devMode}, "POST");
    return res.result;
}

export async function voidTransport(shipmentId: string, devMode?: boolean): Promise<CommonTransportResult> {
    const res = await makeCall(`spApi/fulfillmentInbound/voidTransport`, {shipmentId, devMode}, "POST");
    return res.result;
}

const downloadPdf = async (url: string, filename: string) => {
    const link = document.createElement("a");
    link.download = filename;
    link.href = url;
    link.click();
};

export async function getLabels(shipment: InboundShipmentV2, boxIds?: string[], numberOfPallets?: number, devMode?: boolean): Promise<any> {
    const res = await makeCall(`shipments_v2/boxing_v2/getLabels`, {devMode}, "POST", {shipment, boxIds, numberOfPallets});
    const {boxes: boxLabels, pallets: palletLabels} = res.result;

    const blob = new Blob([Uint8Array.from(boxLabels.data).buffer], {
        type: "application/pdf",
    });
    const blobURL = URL.createObjectURL(blob);
    downloadPdf(blobURL, `${shipment.shipmentConfirmationId}-boxes.pdf`);

    if (palletLabels) {
        const palletBlob = new Blob([Uint8Array.from(palletLabels.data).buffer], {
            type: "application/pdf",
        });
        const palletBlobURL = URL.createObjectURL(palletBlob);
        downloadPdf(palletBlobURL, `${shipment.shipmentConfirmationId}-pallets.pdf`);
    }
    return res.result;
}

export async function getBillOfLading(shipmentId: string, devMode?: boolean): Promise<any> {
    const res = await makeCall(`shipments_v2/boxing_v2/getBillOfLading`, {devMode, shipmentId});
    const blob = new Blob([Uint8Array.from(res.result.data).buffer], {
        type: "application/pdf",
    });
    const blobURL = URL.createObjectURL(blob);
    downloadPdf(blobURL, `${shipmentId}-bill-of-lading.pdf`);
    return res.result;
}

export async function removeItemFromShipment(
    shipmentId: string,
    sku: string,
    quantity: number,
    devMode?: boolean
): Promise<InboundShipmentItem[]> {
    const res = await makeCall(`shipments_v2/boxing/deleteItem`, {shipmentId, sku, quantity, devMode}, "DELETE");
    return res.result;
}

export async function waitForOperation(
    operationId: string,
    milliseconds: number = 2000,
    devMode?: boolean
): Promise<InboundOperationStatus> {
    let status: InboundOperationStatus | undefined;
    while (!status || status.operationStatus === "IN_PROGRESS") {
        await new Promise((resolve) => setTimeout(resolve, milliseconds));
        status = await getInboundOperationStatus({operationId, devMode});
        if (status.operationStatus === "FAILED") {
            throw new Error(status.operationProblems?.map((problem) => `${problem.message} (${problem.details})`).join(" | "));
        }
    }

    return status;
}

export async function createInboundPlan(
    name: string,
    products: WarehouseProduct[],
    address: WarehouseAddress,
    contactInfo: WarehouseContact,
    devMode?: boolean
): Promise<CreateInboundPlanResponse> {
    // If some items do not have SKU, throw an error
    const productsWithoutSKU = products.filter((product) => !product.sku);
    if (productsWithoutSKU.length > 0) {
        throw new Error(`The following products do not have SKU: ${productsWithoutSKU.map((product) => product.asin).join(", ")}`);
    }

    let request: CreateInboundPlanRequest = {
        name,
        sourceAddress: {
            name: address.name,
            addressLine1: address.addressLine1,
            city: address.city,
            stateOrProvinceCode: address.stateOrProvinceCode,
            postalCode: address.postalCode,
            countryCode: address.countryCode,
            companyName: address.companyName,
            // @ts-ignore
            phoneNumber: address.phoneNumber,
        },
        contactInformation: {
            email: contactInfo.email,
            phoneNumber: contactInfo.phone,
            name: contactInfo.name,
        },
        destinationMarketplaces: [MARKETPLACE_ID],
        items: products.map((product) => ({
            msku: product.sku || "",
            quantity: product.quantity,
            labelOwner: product.labelOwner || "SELLER",
            prepOwner: product.prepOwner || "SELLER",
        })),
    };

    if (address.addressLine2 && address.addressLine2.trim() !== "") {
        request.sourceAddress.addressLine2 = address.addressLine2;
    }

    const res = await makeCall("spApi/fulfillmentInbound2024/createInboundPlan", {devMode}, "POST", request);
    const inboundResponse = res.result as CreateInboundPlanResponse;

    // await waitForOperation(inboundResponse.operationId, 2000, false);

    return inboundResponse;
}

export async function listPlacementOptions(inboundPlanId: string, pageSize: number = 10): Promise<PlacementOption[]> {
    const res = await makeCall("spApi/fulfillmentInbound2024/listPlacementOptions", {inboundPlanId, pageSize, devMode: false});
    const inboundResponse = res.result as ListPlacementOptionsResponse;
    const placementOptions = inboundResponse.placementOptions || [];
    let nextToken = inboundResponse.pagination?.nextToken;
    while (nextToken) {
        // Wait for 0.5 seconds to avoid throttling
        await new Promise((resolve) => setTimeout(resolve, 500));
        const nextRes = await makeCall("spApi/fulfillmentInbound2024/listPlacementOptions", {
            inboundPlanId,
            paginationToken: nextToken,
            pageSize,
            devMode: false,
        });
        const nextInboundResponse = nextRes.result as ListPlacementOptionsResponse;
        placementOptions.push(...(nextInboundResponse.placementOptions || []));
        nextToken = nextInboundResponse.pagination?.nextToken;
    }

    return placementOptions;
}

export async function getShipment(inboundPlanId: string, shipmentId: string): Promise<Shipment> {
    const res = await makeCall("spApi/fulfillmentInbound2024/getShipment", {inboundPlanId, shipmentId, devMode: false});
    return res.result;
}

export async function listShipmentItems(inboundPlanId: string, shipmentId: string): Promise<ShipmentItem[]> {
    const res = await makeCall("spApi/fulfillmentInbound2024/listShipmentItems", {inboundPlanId, shipmentId, devMode: false});
    const items: ShipmentItem[] = res.result.items || [];
    let nextToken = res.result.pagination?.nextToken;
    while (nextToken) {
        // Wait for 0.5 seconds to avoid throttling
        await new Promise((resolve) => setTimeout(resolve, 500));
        const nextRes = await makeCall("spApi/fulfillmentInbound2024/listShipmentItems", {
            inboundPlanId,
            shipmentId,
            paginationToken: nextToken,
            devMode: false,
        });
        items.push(...(nextRes.result.items || []));
        nextToken = nextRes.result.pagination?.nextToken;
    }
    return items;
}

export async function confirmPlacementOption(inboundPlanId: string, placementOptionId: string, devMode?: boolean): Promise<string> {
    const res = await makeCall("spApi/fulfillmentInbound2024/confirmPlacementOption", {inboundPlanId, placementOptionId, devMode}, "POST");
    return res.result.operationId;
}

export async function setShipmentPackingInformation(
    inboundPlanId: string,
    shipment: InboundShipmentV2,
    boxes: InboundBoxV2[],
    devMode?: boolean
): Promise<SetPackingInformationResponse> {
    const templateName = shipment.shipmentType === "SP" ? "SP_BOXES" : "LTL_BOXES";
    const body: FbaInboundApiSetPackingInformationRequest = {
        inboundPlanId,
        body: {
            packageGroupings: [
                {
                    shipmentId: shipment.shipmentId,
                    boxes: boxes.map((box) => ({
                        boxId: box.id,
                        contentInformationSource: "BOX_CONTENT_PROVIDED",
                        dimensions: {
                            width: box.dimensions?.width || 0,
                            height: box.dimensions?.height || 0,
                            length: box.dimensions?.length || 0,
                            unitOfMeasurement: "IN",
                        },
                        weight: {
                            value: box.dimensions?.weight || 0,
                            unit: "LB",
                        },
                        quantity: 1,
                        items: box.items.map((item) => ({
                            labelOwner: "SELLER",
                            prepOwner: "SELLER",
                            msku: item.msku,
                            quantity: item.quantityInBox || 0,
                        })),
                        templateName,
                    })),
                },
            ],
        },
    };

    const res = await makeCall("spApi/fulfillmentInbound2024/setPackingInformation", {devMode}, "POST", body);
    return res.result;
}

export async function setPackingGroupInformation(
    inboundPlanId: string,
    packingGroups: InboundPackingGroup[],
    boxes: InboundBoxV2[],
    devMode?: boolean
): Promise<SetPackingInformationResponse> {
    const body: FbaInboundApiSetPackingInformationRequest = {
        inboundPlanId,
        body: {
            packageGroupings: packingGroups.map((group) => {
                const groupBoxes = boxes.filter((box) => box.packingGroupId === group.packingGroupId);
                return {
                    packingGroupId: group.packingGroupId,
                    boxes: groupBoxes.map((box) => ({
                        boxId: box.id,
                        contentInformationSource: "BOX_CONTENT_PROVIDED",
                        dimensions: {
                            width: box.dimensions?.width || 0,
                            height: box.dimensions?.height || 0,
                            length: box.dimensions?.length || 0,
                            unitOfMeasurement: "IN",
                        },
                        weight: {
                            value: box.dimensions?.weight || 0,
                            unit: "LB",
                        },
                        quantity: 1,
                        items: box.items.map((item) => ({
                            labelOwner: "SELLER",
                            prepOwner: "SELLER",
                            msku: item.msku,
                            quantity: item.quantityInBox || 0,
                        })),
                    })),
                };
            }),
        },
    };
    const res = await makeCall("spApi/fulfillmentInbound2024/setPackingInformation", {devMode}, "POST", body);
    return res.result;
}

export async function generateTransportationOptions(
    request: FbaInboundApiGenerateTransportationOptionsRequest,
    devMode?: boolean
): Promise<GenerateTransportationOptionsResponse> {
    const res = await makeCall("spApi/fulfillmentInbound2024/generateTransportationOptions", {devMode}, "POST", request);
    return res.result;
}

export async function listTransportationOptions(inboundPlanId: string, shipmentId: string): Promise<TransportationOption[]> {
    const res = await makeCall("spApi/fulfillmentInbound2024/listTransportationOptions", {inboundPlanId, shipmentId, devMode: false});
    const options: TransportationOption[] = res.result.transportationOptions || [];
    let nextToken = res.result.pagination?.nextToken;
    while (nextToken) {
        // Wait for 0.5 seconds to avoid throttling
        await new Promise((resolve) => setTimeout(resolve, 500));
        const nextRes = await makeCall("spApi/fulfillmentInbound2024/listTransportationOptions", {
            inboundPlanId,
            shipmentId,
            paginationToken: nextToken,
            devMode: false,
        });
        options.push(...(nextRes.result.transportationOptions || []));
        nextToken = nextRes.result.pagination?.nextToken;
    }
    return options;
}

export async function confirmTransportationOptions(
    request: FbaInboundApiConfirmTransportationOptionsRequest,
    devMode?: boolean
): Promise<ConfirmTransportationOptionsResponse> {
    const res = await makeCall("spApi/fulfillmentInbound2024/confirmTransportationOptions", {devMode}, "POST", request);
    return res.result;
}

export async function generatePackingOptions(inboundPlanId: string, devMode?: boolean): Promise<string> {
    const res = await makeCall("spApi/fulfillmentInbound2024/generatePackingOptions", {devMode}, "POST", {inboundPlanId});
    return res.result.operationId;
}

export async function listPackingOptions(inboundPlanId: string, pageSize: number = 20): Promise<PackingOption[]> {
    const res = await makeCall("spApi/fulfillmentInbound2024/listPackingOptions", {inboundPlanId, pageSize, devMode: false});
    const packingOptions = res.result.packingOptions || [];
    let nextToken = res.result.pagination?.nextToken;

    while (nextToken) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const nextRes = await makeCall("spApi/fulfillmentInbound2024/listPackingOptions", {
            inboundPlanId,
            paginationToken: nextToken,
            pageSize,
            devMode: false,
        });
        packingOptions.push(...(nextRes.result.packingOptions || []));
        nextToken = nextRes.result.pagination?.nextToken;
    }

    return packingOptions;
}

export async function listPackingGroupItems(
    inboundPlanId: string,
    packingGroupId: string,
    pageSize: number = 20,
    devMode: boolean = false
): Promise<Item[]> {
    const res = await makeCall("spApi/fulfillmentInbound2024/listPackingGroupItems", {
        inboundPlanId,
        packingGroupId,
        pageSize,
        devMode,
    });
    const items = res.result.items || [];
    let nextToken = res.result.pagination?.nextToken;

    while (nextToken) {
        // Wait for 0.5 seconds to avoid throttling
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const nextRes = await makeCall("spApi/fulfillmentInbound2024/listPackingGroupItems", {
            inboundPlanId,
            packingGroupId,
            paginationToken: nextToken,
            pageSize,
            devMode,
        });
        items.push(...(nextRes.result.items || []));
        nextToken = nextRes.result.pagination?.nextToken;
    }

    return items;
}

export async function confirmPackingOption(
    inboundPlanId: string,
    packingOptionId: string,
    devMode?: boolean
): Promise<ConfirmPackingOptionResponse> {
    const res = await makeCall("spApi/fulfillmentInbound2024/confirmPackingOption", {devMode}, "POST", {inboundPlanId, packingOptionId});
    return res.result;
}

export async function listShipmentBoxes(inboundPlanId: string, shipmentId: string, devMode: boolean = false): Promise<Box[]> {
    const res = await makeCall("spApi/fulfillmentInbound2024/listShipmentBoxes", {
        inboundPlanId,
        shipmentId,
        devMode,
    });
    const boxes = res.result.boxes || [];
    let nextToken = res.result.pagination?.nextToken;

    while (nextToken) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        const nextRes = await makeCall("spApi/fulfillmentInbound2024/listShipmentBoxes", {
            inboundPlanId,
            shipmentId,
            paginationToken: nextToken,
            devMode,
        });
        boxes.push(...(nextRes.result.boxes || []));
        nextToken = nextRes.result.pagination?.nextToken;
    }

    return boxes;
}

export async function setPrepDetails(details: SetPrepDetailsRequest, devMode: boolean = false): Promise<SetPrepDetailsResponse> {
    const res = await makeCall("spApi/fulfillmentInbound2024/setPrepDetails", {devMode}, "POST", details);
    return res.result;
}

export async function cancelInboundPlan(inboundPlanId: string, devMode: boolean = false): Promise<CancelInboundPlanResponse> {
    const res = await makeCall("spApi/fulfillmentInbound2024/cancelInboundPlan", {inboundPlanId, devMode}, "PUT");
    return res.result;
}
