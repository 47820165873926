import {
    FreightInformation,
    InboundOperationStatus,
    PalletInput,
} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model-v20240320";
import {getSearchType} from "../../../components/warehouse/common/helpers";
import {sumIncentives, verifyPackingOption} from "../../../components/warehouse/pack-first/helpers";
import {countItemsByKey, randID} from "../../../components/warehouse/pack-later/boxing/helpers";
import {
    cancelInboundPlan,
    confirmPlacementOption,
    confirmTransportationOptions,
    generatePlacementOptions,
    generateTransportationOptions,
    getBillOfLading,
    getLabels,
    getShipment,
    listPlacementOptions,
    listTransportationOptions,
    setPackingGroupInformation,
    waitForOperation,
} from "../../../services/SPApiService";
import {
    getPackingOptions,
    getShipmentsFromAmazon,
    removeInboundPlan,
    removeShipmentsData,
    restoreBatch,
    saveShipmentsData,
} from "../../../services/WarehouseService";
import {AppState, ImmerStateCreator, PackFirstBoxingDataSlice} from "../../../types/storeTypes";
import {Dimensions, InboundBoxV2, InboundPackingGroup, InboundShipmentItemV2} from "../../../types/WarehouseTypes";
import {normalize} from "../../helpers";
import {notification} from "antd";

const devMode = false;

export const createPackFirstBoxingDataSlice: ImmerStateCreator<AppState, PackFirstBoxingDataSlice> = (set, get) => ({
    packFirstBoxingData: {
        isDataLoading: false,
        packingOptions: {
            byId: {},
            allIds: [],
        },
        packingGroups: {
            byId: {},
            allIds: [],
        },
        boxes: {
            byId: {},
            allIds: [],
        },
        shipments: {
            byId: {},
            allIds: [],
        },
        loadingPackingOptions: [],
        loadingPlacementOptions: [],
    },
    packFirstBoxingActions: {
        getData: async () => {
            const {packingOptions, items, boxes, shipments} = await getPackingOptions();

            const finalBoxes: InboundBoxV2[] = [];
            const finalPackingGroups: InboundPackingGroup[] = [];
            packingOptions.forEach((packingOption) => {
                const verifiedData = verifyPackingOption(packingOption, boxes, items);
                finalBoxes.push(...verifiedData.boxes);
                finalPackingGroups.push(...verifiedData.packingGroups);
            });

            const optionsState = normalize(packingOptions, "packingOptionId");
            const packingGroupsState = normalize(finalPackingGroups, "packingGroupId");
            const boxesState = normalize(finalBoxes, "id");
            const shipmentsState = normalize(shipments, "shipmentId");

            set((state) => {
                state.packFirstBoxingData.packingOptions = optionsState;
                state.packFirstBoxingData.packingGroups = packingGroupsState;
                state.packFirstBoxingData.boxes = boxesState;
                state.packFirstBoxingData.shipments = shipmentsState;
                state.packFirstBoxingData.isDataLoading = false;
            });
        },
        scanItem: (code: string) => {
            const data = get().packFirstBoxingData;
            const packingOption = data.packingOptions.byId[data.selectedPackingOptionId || ""];

            if (!packingOption) {
                throw new Error("Select a packing option first");
            }

            if (packingOption?.confirmTransportationOptionsStatus?.operationStatus === "SUCCESS") {
                throw new Error("Cannot add items after transportation options have been confirmed");
            }

            const searchType = getSearchType(code);
            if (!searchType || searchType === "LPN") {
                throw new Error("The code is not recognized");
            }
            const searchTypeMap: {[key: string]: keyof InboundShipmentItemV2} = {
                UPC: "upc",
                FNSKU: "fnsku",
                ASIN: "asin",
            };
            const key = searchTypeMap[searchType];

            const packingGroup = data.packingGroups.byId[packingOption.activePackingGroupId || ""];
            if (!packingGroup) {
                throw new Error("Select a packing group first");
            }

            const item = packingGroup.expectedItems.find((i) => i[key] === code);
            if (!item) {
                throw new Error("Item not found in the packing group");
            }

            get().packFirstBoxingActions.addItem(item, 1, packingGroup.packingGroupId);
        },
        setSelectedPackingOptionId: (packingOptionId: string | undefined) => {
            set((state) => {
                if (packingOptionId !== state.packFirstBoxingData.selectedPackingOptionId) {
                    state.packFirstBoxingData.selectedPlacementOptionId = undefined;
                    state.packFirstBoxingData.selectedPackingOptionId = packingOptionId;
                }
            });
        },
        setSelectedPlacementOptionId: (placementOptionId: string | undefined) => {
            set((state) => {
                state.packFirstBoxingData.selectedPlacementOptionId = placementOptionId;
            });
        },
        setActivePackingGroupId: (packingOptionId: string, packingGroupId: string | undefined) => {
            set((state) => {
                state.packFirstBoxingData.packingOptions.byId[packingOptionId].activePackingGroupId = packingGroupId;
            });
        },
        setActiveBoxId: (packingGroupId: string, boxId: string | undefined) => {
            set((state) => {
                state.packFirstBoxingData.packingGroups.byId[packingGroupId].activeBoxId = boxId;
            });
        },
        addBox: (packingGroupId: string) => {
            const newBoxId = randID();
            const stateData = get().packFirstBoxingData;
            const packingGroup = stateData.packingGroups.byId[packingGroupId];
            if (!packingGroup) {
                throw new Error("Packing group not found");
            }

            set((state) => {
                const boxNumber =
                    stateData.boxes.allIds.map((id) => stateData.boxes.byId[id]).filter((b) => b.packingGroupId === packingGroupId).length +
                    1;

                // Create new box
                state.packFirstBoxingData.boxes.byId[newBoxId] = {
                    id: newBoxId,
                    packingGroupId: packingGroupId,
                    inboundPlanId: packingGroup.inboundPlanId,
                    number: boxNumber,
                    items: [],
                };
                state.packFirstBoxingData.boxes.allIds.push(newBoxId);
                state.packFirstBoxingData.packingGroups.byId[packingGroupId].activeBoxId = newBoxId;
            });

            get().packFirstBoxingActions.updatePackingOptionStatus(packingGroup.packingOptionId, {
                lastUpdate: new Date().getTime(),
            });

            get().packFirstBoxingActions.saveData({
                boxIds: get().packFirstBoxingData.boxes.allIds,
                packingOptionIds: [packingGroup.packingOptionId],
            });

            return newBoxId;
        },
        removeBox: (boxId: string) => {
            const box = get().packFirstBoxingData.boxes.byId[boxId];
            const packingGroup = get().packFirstBoxingData.packingGroups.byId[box.packingGroupId || ""];
            if (!box || !packingGroup) return;

            // Get all boxes in this packing group
            const packingGroupBoxes = Object.values(get().packFirstBoxingData.boxes.byId).filter(
                (b) => b.packingGroupId === box.packingGroupId
            );

            set((state) => {
                const box = state.packFirstBoxingData.boxes.byId[boxId];
                if (!box) return;

                // If this is the last box, just clear its items
                if (packingGroupBoxes.length === 1) {
                    box.items = [];
                    box.number = 1;
                    delete box.dimensions;
                    return;
                }

                // Otherwise remove the box completely
                delete state.packFirstBoxingData.boxes.byId[boxId];
                state.packFirstBoxingData.boxes.allIds = state.packFirstBoxingData.boxes.allIds.filter((id) => id !== boxId);
            });

            get().packFirstBoxingActions.updatePackingOptionStatus(packingGroup.packingOptionId, {
                lastUpdate: new Date().getTime(),
            });

            if (packingGroupBoxes.length > 1) {
                // Remove this box from DB
                removeShipmentsData([], [boxId]);
                get().packFirstBoxingActions.saveData({packingOptionIds: [packingGroup.packingOptionId]});
            } else {
                get().packFirstBoxingActions.saveData({boxIds: [boxId], packingOptionIds: [packingGroup.packingOptionId]});
            }
        },
        addBoxDimensions: (boxId: string, dimensions: Dimensions) => {
            const box = get().packFirstBoxingData.boxes.byId[boxId];
            const packingGroup = get().packFirstBoxingData.packingGroups.byId[box.packingGroupId || ""];
            if (!box || !packingGroup) return;

            set((state) => {
                const box = state.packFirstBoxingData.boxes.byId[boxId];
                if (box) {
                    box.dimensions = dimensions;
                }
            });

            get().packFirstBoxingActions.updatePackingOptionStatus(packingGroup.packingOptionId, {
                lastUpdate: new Date().getTime(),
            });

            get().packFirstBoxingActions.saveData({boxIds: [boxId], packingOptionIds: [packingGroup.packingOptionId]});
        },
        duplicateBox: (boxId: string) => {
            const originalBox = get().packFirstBoxingData.boxes.byId[boxId];
            const packingGroup = get().packFirstBoxingData.packingGroups.byId[originalBox.packingGroupId || ""];
            if (!originalBox || !packingGroup) return;

            const newBoxId = randID();
            set((state) => {
                const boxNumber = state.packFirstBoxingData.boxes.allIds.length + 1;

                // Create new box as a copy
                state.packFirstBoxingData.boxes.byId[newBoxId] = {
                    ...originalBox,
                    id: newBoxId,
                    number: boxNumber,
                    items: [...originalBox.items],
                };
                state.packFirstBoxingData.boxes.allIds.push(newBoxId);
            });

            get().packFirstBoxingActions.updatePackingOptionStatus(packingGroup.packingOptionId, {
                lastUpdate: new Date().getTime(),
            });

            get().packFirstBoxingActions.saveData({boxIds: [newBoxId], packingOptionIds: [packingGroup.packingOptionId]});
            return newBoxId;
        },
        addItem: (item, quantity, packingGroupId) => {
            const packingGroup = get().packFirstBoxingData.packingGroups.byId[packingGroupId];
            if (!packingGroup) return;

            const boxes = Object.values(get().packFirstBoxingData.boxes.byId).filter((b) => b.packingGroupId === packingGroupId);
            let boxId = packingGroup.activeBoxId;
            if (!boxId) {
                boxId = boxes[0]?.id;
            }

            if (!boxId) return;

            // Count already added units
            const units = boxes.reduce((sum, box) => {
                const existingItem = box.items.find((i) => i.msku === item.msku);
                return sum + (existingItem?.quantityInBox || 0);
            }, 0);

            if (units + quantity > item.quantity) {
                notification.error({
                    message: "Cannot add item",
                    description: "Adding this item would exceed the shipped quantity",
                });
                return;
            }

            set((state) => {
                const box = state.packFirstBoxingData.boxes.byId[boxId!];
                const existingItem = box.items.find((i) => i.msku === item.msku);
                if (existingItem) {
                    existingItem.quantityInBox = quantity + (existingItem.quantityInBox || 0);
                } else {
                    box.items.push({...item, quantityInBox: quantity});
                }
            });

            get().packFirstBoxingActions.updatePackingOptionStatus(packingGroup.packingOptionId, {
                lastUpdate: new Date().getTime(),
            });
            get().packFirstBoxingActions.saveData({boxIds: [boxId!]});
        },
        removeItem: (sku, boxId) => {
            const box = get().packFirstBoxingData.boxes.byId[boxId];
            const packingGroup = get().packFirstBoxingData.packingGroups.byId[box.packingGroupId || ""];
            if (!box || !packingGroup) return;

            set((state) => {
                const box = state.packFirstBoxingData.boxes.byId[boxId];
                if (box) {
                    box.items = box.items.filter((i) => i.msku !== sku);
                }
            });

            get().packFirstBoxingActions.updatePackingOptionStatus(packingGroup.packingOptionId, {
                lastUpdate: new Date().getTime(),
            });
            get().packFirstBoxingActions.saveData({boxIds: [boxId], packingOptionIds: [packingGroup.packingOptionId]});
        },
        changeItemQuantity: (sku, boxId, quantity) => {
            const box = get().packFirstBoxingData.boxes.byId[boxId];
            const packingGroup = get().packFirstBoxingData.packingGroups.byId[box.packingGroupId || ""];
            if (!box || !packingGroup) return;

            const boxes = Object.values(get().packFirstBoxingData.boxes.byId).filter((b) => b.packingGroupId === box.packingGroupId);

            const otherBoxesQuantity = boxes
                .filter((b) => b.id !== boxId)
                .reduce((sum, b) => {
                    const item = b.items.find((i) => i.msku === sku);
                    return sum + (item?.quantityInBox || 0);
                }, 0);

            set((state) => {
                const box = state.packFirstBoxingData.boxes.byId[boxId];
                const item = box.items.find((i) => i.msku === sku);
                if (item && quantity >= 0 && otherBoxesQuantity + quantity <= item.quantity) {
                    item.quantityInBox = quantity;
                }
            });

            get().packFirstBoxingActions.updatePackingOptionStatus(packingGroup.packingOptionId, {
                lastUpdate: new Date().getTime(),
            });
            get().packFirstBoxingActions.saveData({boxIds: [boxId], packingOptionIds: [packingGroup.packingOptionId]});
        },
        saveData: async ({packingOptionIds, boxIds, shipmentIds}) => {
            try {
                const packingOptionsState = get().packFirstBoxingData.packingOptions;
                const boxesState = get().packFirstBoxingData.boxes;

                const packingOptions = packingOptionIds?.map((id) => packingOptionsState.byId[id]);
                const boxes = boxIds?.map((id) => boxesState.byId[id]);

                const shipments = shipmentIds?.map((id) => get().packFirstBoxingData.shipments.byId[id]);

                // Save the data
                await saveShipmentsData(shipments || [], [], boxes || [], packingOptions || []);
            } catch (e: any) {
                notification.error({
                    message: "Error saving data",
                    description: e.message,
                });
            }
        },
        addLoadingPackingOption: (id, message) => {
            set((state) => {
                state.packFirstBoxingData.loadingPackingOptions = state.packFirstBoxingData.loadingPackingOptions.filter(
                    (s) => s.id !== id
                );
                state.packFirstBoxingData.loadingPackingOptions.push({id, message});
            });
        },
        removeLoadingPackingOption: (id) => {
            set((state) => {
                state.packFirstBoxingData.loadingPackingOptions = state.packFirstBoxingData.loadingPackingOptions.filter(
                    (s) => s.id !== id
                );
            });
        },
        updatePackingOptionStatus: (packingOptionId, status) => {
            set((state) => {
                const defaultStatus: any = {
                    packingInformationStatus: null,
                    packingInformationTimestamp: null,
                    ...status,
                };

                for (const key in defaultStatus) {
                    if (defaultStatus[key] !== null) {
                        // @ts-ignore
                        state.packFirstBoxingData.packingOptions.byId[packingOptionId][key] = status[key];
                    }
                }
            });
        },
        setPackingOptionViewMode: (packingOptionId, viewMode) => {
            set((state) => {
                state.packFirstBoxingData.packingOptions.byId[packingOptionId].viewMode = viewMode;
            });
        },
        setShipmentType: (shipmentId, shipmentType) => {
            set((state) => {
                state.packFirstBoxingData.shipments.byId[shipmentId].shipmentType = shipmentType;
                state.packFirstBoxingData.shipments.byId[shipmentId].transportationOptions = [];
                state.packFirstBoxingData.shipments.byId[shipmentId].selectedTransportationOptionId = undefined;
            });
            get().packFirstBoxingActions.saveData({shipmentIds: [shipmentId]});
        },
        setFreightClass: (shipmentId, freightClass) => {
            set((state) => {
                state.packFirstBoxingData.shipments.byId[shipmentId].freightClass = freightClass;
            });
            get().packFirstBoxingActions.saveData({shipmentIds: [shipmentId]});
        },
        setReadyToShipDate: (shipmentId, readyToShipDate) => {
            set((state) => {
                state.packFirstBoxingData.shipments.byId[shipmentId].readyToShipDate = readyToShipDate;
            });
            get().packFirstBoxingActions.saveData({shipmentIds: [shipmentId]});
        },
        setSelectedTransportationOptionId: (shipmentId, transportationOptionId) => {
            set((state) => {
                state.packFirstBoxingData.shipments.byId[shipmentId].selectedTransportationOptionId = transportationOptionId;
            });
            get().packFirstBoxingActions.saveData({shipmentIds: [shipmentId]});
        },
        addPallet: (shipmentId) => {
            const palletId = randID();
            set((state) => {
                const pallets = state.packFirstBoxingData.shipments.byId[shipmentId].pallets;
                const dims: Dimensions = {length: 48, width: 40, height: 48, weight: 40};
                if (pallets) {
                    pallets.push({
                        id: palletId,
                        dimensions: {...dims},
                    });
                } else {
                    state.packFirstBoxingData.shipments.byId[shipmentId].pallets = [{id: palletId, dimensions: {...dims}}];
                }
            });
            get().packFirstBoxingActions.saveData({shipmentIds: [shipmentId]});
            return palletId;
        },
        removePallet: (shipmentId, palletId) => {
            set((state) => {
                state.packFirstBoxingData.shipments.byId[shipmentId].pallets = state.packFirstBoxingData.shipments.byId[
                    shipmentId
                ].pallets?.filter((p) => p.id !== palletId);
            });
            get().packFirstBoxingActions.saveData({shipmentIds: [shipmentId]});
        },
        updatePalletDimensions: (shipmentId, palletId, dimensions) => {
            set((state) => {
                const pallet = state.packFirstBoxingData.shipments.byId[shipmentId].pallets?.find((p) => p.id === palletId);
                if (pallet) {
                    pallet.dimensions = dimensions;
                }
            });
            get().packFirstBoxingActions.saveData({shipmentIds: [shipmentId]});
        },
        submitBoxes: async (packingOptionId) => {
            const packingOption = get().packFirstBoxingData.packingOptions.byId[packingOptionId];
            try {
                if (!packingOption) {
                    throw new Error("Packing option not found");
                }

                const boxes = Object.values(get().packFirstBoxingData.boxes.byId).filter(
                    (b) => b.inboundPlanId === packingOption.inboundPlanId
                );
                const packingGroups = Object.values(get().packFirstBoxingData.packingGroups.byId).filter(
                    (g) => g.inboundPlanId === packingOption.inboundPlanId
                );

                // Check if all boxes have dimensions
                const boxesWithoutDimensions = boxes.filter((b) => !b.dimensions);
                if (boxesWithoutDimensions.length > 0) {
                    throw new Error("All boxes must have dimensions before submitting the shipment");
                }

                // Check if all boxes have items
                const boxesWithoutItems = boxes.filter((b) => b.items.length === 0);
                if (boxesWithoutItems.length > 0) {
                    throw new Error("All boxes must have items before submitting the shipment");
                }

                // Check if all items have been assigned to a box
                const boxed = countItemsByKey(boxes);
                for (const packingGroup of packingGroups) {
                    const remainingItems = packingGroup.expectedItems
                        .map((i) => ({
                            ...i,
                            quantityLeft: i.quantity - (boxed[i.msku] || 0),
                        }))
                        .filter((i) => i.quantityLeft > 0);
                    if (remainingItems.length > 0) {
                        throw new Error("All items must be assigned to a box before submitting the shipment");
                    }
                }

                get().packFirstBoxingActions.addLoadingPackingOption(packingOptionId, "Submitting boxes");
                const packingRes = await setPackingGroupInformation(packingOption.inboundPlanId, packingGroups, boxes, devMode);
                const waitRes = await waitForOperation(packingRes.operationId, 2000, devMode);

                get().packFirstBoxingActions.updatePackingOptionStatus(packingOptionId, {
                    packingInformationStatus: waitRes,
                    packingInformationTimestamp: new Date().getTime(),
                });
                if (waitRes.operationStatus === "SUCCESS") {
                    notification.success({
                        message: "Boxes submitted",
                        description: "The boxes have been submitted successfully",
                    });
                } else {
                    const problems = waitRes.operationProblems.map((problem) => problem.message).join(" | ");
                    const message = problems ? `Failed: ${problems}` : "Failed to confirm placement";
                    throw new Error(message);
                }
            } catch (e: any) {
                notification.error({
                    message: "Error",
                    description: e.message,
                });
            }
            get().packFirstBoxingActions.saveData({packingOptionIds: [packingOptionId]});
            get().packFirstBoxingActions.removeLoadingPackingOption(packingOptionId);
        },
        generatePlacementOptions: async (packingOptionId: string) => {
            const packingOption = get().packFirstBoxingData.packingOptions.byId[packingOptionId];
            try {
                if (!packingOption) {
                    throw new Error("Packing option not found");
                }

                // Start the operation
                get().packFirstBoxingActions.addLoadingPackingOption(packingOptionId, "Generating placement options");

                // Call the API to generate placement options
                const res = await generatePlacementOptions({inboundPlanId: packingOption.inboundPlanId, body: {}}, devMode);

                // Wait for the operation to complete
                const operationStatus = await waitForOperation(res.operationId, 2000, devMode);

                // Update the status in the store
                set((state) => {
                    const option = state.packFirstBoxingData.packingOptions.byId[packingOptionId];
                    if (option) {
                        option.generatePlacementOptionsStatus = operationStatus;
                    }
                });

                if (operationStatus.operationStatus === "SUCCESS") {
                    // Fetch all placement options
                    const placementOptions = await listPlacementOptions(packingOption.inboundPlanId);

                    // Sort placement options by fees
                    placementOptions.sort((a, b) => sumIncentives(a.fees) - sumIncentives(b.fees));

                    // Save placement options in the store
                    set((state) => {
                        const option = state.packFirstBoxingData.packingOptions.byId[packingOptionId];
                        if (option) {
                            option.placementOptions = placementOptions;
                            option.viewMode = "PLACEMENT";
                        }
                        // Set the first placement option as selected
                        if (placementOptions.length > 0) {
                            state.packFirstBoxingData.selectedPlacementOptionId = placementOptions[0].placementOptionId;
                        }
                    });

                    notification.success({
                        message: "Placement options generated",
                        description: "Placement options have been generated successfully",
                    });
                } else {
                    const problems = operationStatus.operationProblems?.map((problem) => problem.message).join(" | ");
                    throw new Error(problems || "Failed to generate placement options");
                }
            } catch (e: any) {
                notification.error({
                    message: "Error",
                    description: e.message,
                });
            }
            get().packFirstBoxingActions.saveData({packingOptionIds: [packingOptionId]});
            get().packFirstBoxingActions.removeLoadingPackingOption(packingOptionId);
        },
        addLoadingPlacementOption: (id, message) => {
            set((state) => {
                state.packFirstBoxingData.loadingPlacementOptions = state.packFirstBoxingData.loadingPlacementOptions.filter(
                    (s) => s.id !== id
                );
                state.packFirstBoxingData.loadingPlacementOptions.push({id, message});
            });
        },
        removeLoadingPlacementOption: (id) => {
            set((state) => {
                state.packFirstBoxingData.loadingPlacementOptions = state.packFirstBoxingData.loadingPlacementOptions.filter(
                    (s) => s.id !== id
                );
            });
        },
        reloadShipments: async (inboundPlanId: string, placementOptionId: string, shipmentIds: string[]) => {
            try {
                get().packFirstBoxingActions.addLoadingPlacementOption(placementOptionId, "Reloading shipments");
                // Fetch all shipments again
                for (const shipmentId of shipmentIds) {
                    const shipment = await getShipment(inboundPlanId, shipmentId);
                    set((state) => {
                        state.packFirstBoxingData.shipments.byId[shipmentId] = {
                            ...state.packFirstBoxingData.shipments.byId[shipmentId],
                            ...shipment,
                        };
                    });
                    // Wait 1s between requests to avoid throttling
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                }
                notification.success({
                    message: "Shipments reloaded",
                    description: "The shipments have been reloaded successfully",
                });
            } catch (e: any) {
                notification.error({
                    message: "Error",
                    description: e.message,
                });
            }
            get().packFirstBoxingActions.saveData({shipmentIds});
            get().packFirstBoxingActions.removeLoadingPlacementOption(placementOptionId);
        },
        fetchShipments: async (inboundPlanId: string, placementOptionId: string, shipmentIds: string[]) => {
            get().packFirstBoxingActions.addLoadingPlacementOption(placementOptionId, "Fetching shipments");
            const shipmentsLocal = {...get().packFirstBoxingData.shipments.byId};
            const shipmentIdsLocal = [...get().packFirstBoxingData.shipments.allIds];

            const shipments = await getShipmentsFromAmazon(inboundPlanId, shipmentIds);
            for (const shipment of shipments) {
                const tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);

                shipment.readyToShipDate = tomorrow.toISOString();
                shipmentsLocal[shipment.shipmentId] = shipment;

                if (!shipmentIdsLocal.includes(shipment.shipmentId)) {
                    shipmentIdsLocal.push(shipment.shipmentId);
                }
            }

            set((state) => {
                state.packFirstBoxingData.shipments = {
                    byId: shipmentsLocal,
                    allIds: shipmentIdsLocal,
                };
            });

            // Save the fetched data
            await get().packFirstBoxingActions.saveData({
                shipmentIds: shipmentIds,
            });

            get().packFirstBoxingActions.removeLoadingPlacementOption(placementOptionId);
        },
        submitBoxesAndFetchShipments: async (packingOptionId: string) => {
            await get().packFirstBoxingActions.submitBoxes(packingOptionId);
            await get().packFirstBoxingActions.generatePlacementOptions(packingOptionId);
            const selectedPackingOption = get().packFirstBoxingData.packingOptions.byId[packingOptionId];
            notification.info({
                message: "Fetching shipments",
                description: "Please wait while we fetch the shipments for the placement options",
            });
            try {
                // Add all placement options to loading
                selectedPackingOption?.placementOptions?.forEach((po) => {
                    get().packFirstBoxingActions.addLoadingPlacementOption(po.placementOptionId, "Fetching shipments");
                });

                // Fetch shipments for all placement options
                for (const po of selectedPackingOption?.placementOptions || []) {
                    await get().packFirstBoxingActions.fetchShipments(
                        selectedPackingOption.inboundPlanId,
                        po.placementOptionId,
                        po.shipmentIds || []
                    );
                }
            } catch (e: any) {
                notification.error({
                    message: "Error",
                    description: e.message,
                });
            }
            // Remove loading from all placement options
            selectedPackingOption?.placementOptions?.forEach((po) => {
                get().packFirstBoxingActions.removeLoadingPlacementOption(po.placementOptionId);
            });
        },
        generateTransportationOptions: async (inboundPlanId: string, placementOptionId: string, shipmentIds: string[]) => {
            const state = get();
            const contact = state.warehouseContactInfo;

            try {
                if (!contact) {
                    throw new Error("Contact information not found");
                }
                if (shipmentIds.length === 0) {
                    throw new Error("No shipments found");
                }

                get().packFirstBoxingActions.addLoadingPlacementOption(placementOptionId, "Generating transportation options");

                // Prepare configurations for all shipments
                const shipmentTransportationConfigurations = shipmentIds.map((shipmentId) => {
                    const shipment = state.packFirstBoxingData.shipments.byId[shipmentId];
                    const pallets = shipment.pallets || [];

                    let palletInformation: PalletInput[] | undefined = undefined;
                    let freightInformation: FreightInformation | undefined = undefined;
                    if (shipment.shipmentType === "LTL") {
                        if (pallets.length === 0) {
                            throw new Error("LTL shipments must have pallets set");
                        }
                        palletInformation = pallets.map((pallet) => ({
                            stackability: "NON_STACKABLE",
                            dimensions: {
                                height: pallet.dimensions?.height || 0,
                                length: pallet.dimensions?.length || 0,
                                width: pallet.dimensions?.width || 0,
                                unitOfMeasurement: "IN",
                            },
                            quantity: 1,
                            weight: {
                                value: pallet.dimensions?.weight || 0,
                                unit: "LB",
                            },
                        }));
                        freightInformation = {
                            declaredValue: {
                                amount: shipment.declaredValue || 0,
                                code: "USD",
                            },
                            freightClass: shipment.freightClass,
                        };
                    }

                    return {
                        shipmentId: shipment.shipmentId,
                        freightInformation,
                        contactInformation: {
                            name: contact?.name || "",
                            phoneNumber: contact?.phone || "",
                            email: contact?.email || "",
                        },
                        pallets: palletInformation,
                        readyToShipWindow: {
                            start: shipment.readyToShipDate || new Date().toISOString(),
                        },
                    };
                });

                let waitRes: InboundOperationStatus | undefined = undefined;
                try {
                    // Get transport details from backend
                    const res = await generateTransportationOptions(
                        {
                            inboundPlanId,
                            body: {
                                placementOptionId,
                                shipmentTransportationConfigurations: shipmentTransportationConfigurations,
                            },
                        },
                        false
                    );
                    // Wait for operation to complete
                    waitRes = await waitForOperation(res.operationId || "", 2000, devMode);
                } catch (e: any) {
                    // Amazon has an issue with generating transport options for SPD shipments
                    // It returns a warning as an error, so we need to check for that
                    if (!e.message.includes("WARNING")) {
                        throw e;
                    }
                    // Wait 10 seconds before continuing
                    await new Promise((resolve) => setTimeout(resolve, 10000));
                    waitRes = {
                        operationStatus: "SUCCESS",
                        operationId: "",
                        operation: "",
                        operationProblems: [],
                    };
                }
                set((state) => {
                    shipmentIds.forEach((shipmentId) => {
                        const shipment = state.packFirstBoxingData.shipments.byId[shipmentId];
                        if (shipment) {
                            shipment.generateTransportationOptionsStatus = waitRes;
                        }
                    });
                });

                get().packFirstBoxingActions.addLoadingPlacementOption(placementOptionId, "Fetching transport options");

                if (waitRes.operationStatus === "SUCCESS") {
                    // Fetch transport options for all shipments sequentially
                    for (const shipmentId of shipmentIds) {
                        const transportOptions = await listTransportationOptions(inboundPlanId, shipmentId);

                        // Update shipment with transport options
                        set((state) => {
                            const shipment = state.packFirstBoxingData.shipments.byId[shipmentId];
                            if (shipment) {
                                shipment.transportationOptions = transportOptions;
                                const amazonOptions = transportOptions.filter((o) => o.shippingSolution === "AMAZON_PARTNERED_CARRIER");

                                let optionId: string | undefined = undefined;
                                if (shipment.shipmentType === "LTL") {
                                    optionId = amazonOptions.find((o) => o.shippingMode === "FREIGHT_LTL")?.transportationOptionId;
                                } else {
                                    optionId = amazonOptions.find((o) => o.shippingMode === "GROUND_SMALL_PARCEL")?.transportationOptionId;
                                }
                                shipment.selectedTransportationOptionId = optionId;
                            }
                        });

                        // Wait 1s between requests to avoid throttling
                        await new Promise((resolve) => setTimeout(resolve, 1000));
                    }

                    notification.success({
                        message: "Transport options generated",
                        description: "The transport options have been generated successfully for all shipments",
                    });
                } else {
                    const problems = waitRes.operationProblems?.map((problem) => problem.message).join(" | ");
                    throw new Error(problems || "Failed to generate transport options");
                }
            } catch (e: any) {
                notification.error({
                    message: "Error",
                    description: e.message,
                });
            }

            get().packFirstBoxingActions.removeLoadingPlacementOption(placementOptionId);
            get().packFirstBoxingActions.saveData({shipmentIds});
        },
        getLabels: async (shipmentId: string) => {
            const shipment = get().packFirstBoxingData.shipments.byId[shipmentId];
            get().packFirstBoxingActions.addLoadingPlacementOption(shipment.placementOptionId, "Downloading labels");
            try {
                const boxIds = shipment.boxes?.map((b) => b.boxId || "") || [];
                await getLabels(shipment, boxIds, shipment.pallets?.length || 0);

                if (shipment.shipmentType === "LTL") {
                    get().packFirstBoxingActions.addLoadingPlacementOption(shipment.placementOptionId, "Downloading bill of lading");
                    await getBillOfLading(shipment.shipmentConfirmationId || "");
                }
            } catch (err: any) {
                notification.error({
                    message: "Error",
                    description: err.message,
                });
            }
            get().packFirstBoxingActions.removeLoadingPlacementOption(shipment.placementOptionId);
        },
        confirmShipments: async (placementOptionId: string) => {
            const data = get().packFirstBoxingData;
            const packingOption = data.packingOptions.byId[data.selectedPackingOptionId || ""];
            let shipments = data.shipments.allIds
                .map((shipmentId) => data.shipments.byId[shipmentId])
                .filter((s) => s.placementOptionId === placementOptionId);

            try {
                if (!packingOption) {
                    throw new Error("Packing option not found");
                }
                if (shipments.length === 0) {
                    throw new Error("No shipments found");
                }
                if (shipments.some((s) => !s.selectedTransportationOptionId)) {
                    throw new Error("All shipments must have a transportation option selected");
                }

                get().packFirstBoxingActions.addLoadingPlacementOption(placementOptionId, "Confirming shipment");

                // First list all placement options and check if any of them has been confirmed
                let placementOptions = await listPlacementOptions(packingOption.inboundPlanId);
                let placementOption = placementOptions.find((o) => o.status === "ACCEPTED");
                if (!placementOption) {
                    const confirmOperationId = await confirmPlacementOption(packingOption.inboundPlanId, placementOptionId, devMode);

                    const confirmWaitRes = await waitForOperation(confirmOperationId, 2000, devMode);
                    set((state) => {
                        state.packFirstBoxingData.packingOptions.byId[packingOption.packingOptionId].confirmPlacementOptionStatus =
                            confirmWaitRes;
                    });
                    if (confirmWaitRes.operationStatus === "SUCCESS") {
                        notification.success({
                            message: "Placement option confirmed",
                            description: "The placement option has been confirmed successfully",
                        });
                    } else {
                        const problems = confirmWaitRes.operationProblems?.map((problem) => problem.message).join(" | ");
                        throw new Error(problems || "Failed to confirm shipment");
                    }

                    placementOptions = await listPlacementOptions(packingOption.inboundPlanId);
                    placementOption = placementOptions.find((o) => o.placementOptionId === placementOptionId);
                    if (!placementOption) {
                        throw new Error("Placement option not found");
                    }
                }
                set((state) => {
                    state.packFirstBoxingData.packingOptions.byId[packingOption.packingOptionId].placementOptions = placementOptions;
                });

                get().packFirstBoxingActions.saveData({
                    packingOptionIds: [packingOption.packingOptionId],
                });

                const confirmTransportationRes = await confirmTransportationOptions(
                    {
                        inboundPlanId: packingOption.inboundPlanId,
                        body: {
                            transportationSelections: shipments.map((s) => ({
                                shipmentId: s.shipmentId,
                                transportationOptionId: s.selectedTransportationOptionId || "",
                            })),
                        },
                    },
                    devMode
                );
                const confirmTransportationWaitRes = await waitForOperation(confirmTransportationRes.operationId, 2000, devMode);
                set((state) => {
                    state.packFirstBoxingData.packingOptions.byId[packingOption.packingOptionId].confirmTransportationOptionsStatus =
                        confirmTransportationWaitRes;
                });
                // Fetch all shipments again
                for (const shipmentId of placementOption.shipmentIds) {
                    const shipment = await getShipment(packingOption.inboundPlanId, shipmentId);
                    set((state) => {
                        state.packFirstBoxingData.shipments.byId[shipmentId] = {
                            ...state.packFirstBoxingData.shipments.byId[shipmentId],
                            ...shipment,
                        };
                    });
                    // Wait 1s between requests to avoid throttling
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                }
                if (confirmTransportationWaitRes.operationStatus === "SUCCESS") {
                    notification.success({
                        message: "Shipment confirmed",
                        description: "The shipment has been confirmed successfully",
                    });
                }
                get().packFirstBoxingActions.saveData({
                    packingOptionIds: [packingOption.packingOptionId],
                    shipmentIds: placementOption.shipmentIds,
                });
            } catch (e: any) {
                notification.error({
                    message: "Error",
                    description: e.message,
                });
            }

            get().packFirstBoxingActions.removeLoadingPlacementOption(placementOptionId);
        },
        finishPackingOption: async (packingOptionId: string, placementOptionId: string) => {
            get().packFirstBoxingActions.addLoadingPlacementOption(placementOptionId, "Finishing packing option");
            try {
                set((state) => {
                    state.packFirstBoxingData.packingOptions.byId[packingOptionId].processingStatus = "FINISHED";
                });
                await get().packFirstBoxingActions.saveData({
                    packingOptionIds: [packingOptionId],
                });
            } catch (e: any) {
                notification.error({
                    message: "Error",
                    description: e.message,
                });
            }
            get().packFirstBoxingActions.removeLoadingPlacementOption(placementOptionId);
        },
        deletePackingOption: async (packingOptionId: string, restore?: boolean) => {
            const packingOption = get().packFirstBoxingData.packingOptions.byId[packingOptionId];
            if (!packingOption) return;

            try {
                const cancelRes = await cancelInboundPlan(packingOption.inboundPlanId, devMode);
                const cancelWaitRes = await waitForOperation(cancelRes.operationId, 2000, devMode);
                if (cancelWaitRes.operationStatus === "SUCCESS") {
                    notification.success({
                        message: "Inbound plan cancelled",
                        description: "The inbound plan has been cancelled successfully",
                    });
                } else {
                    throw new Error("Failed to cancel inbound plan: " + cancelWaitRes.operationProblems?.map((p) => p.message).join(" | "));
                }
                await removeInboundPlan(packingOption.inboundPlanId);

                if (restore && packingOption.batchId) {
                    await restoreBatch(packingOption.batchId);
                }

                // Remove from local state
                set((state) => {
                    delete state.packFirstBoxingData.packingOptions.byId[packingOptionId];
                    state.packFirstBoxingData.packingOptions.allIds = state.packFirstBoxingData.packingOptions.allIds.filter(
                        (id) => id !== packingOptionId
                    );
                });

                notification.success({
                    message: "Success",
                    description: restore ? "Batch restored successfully" : "Packing option deleted successfully",
                });
            } catch (error: any) {
                notification.error({
                    message: "Error",
                    description: error.message,
                });
                throw error;
            }
        },
    },
});
