import React, {useState} from "react";
import {Modal, Button, Typography, Space} from "antd";
import {InboundPackingOption} from "../../../types/WarehouseTypes";
import {useStore} from "../../../store/useStore";
import {useAuth} from "../../../contexts/AuthContext";
import {useQueryClient} from "@tanstack/react-query";

const {Text} = Typography;

interface DeletePackingOptionDialogProps {
    open: boolean;
    packingOption?: InboundPackingOption;

    onCancel: () => void;
}

const DeletePackingOptionDialog: React.FC<DeletePackingOptionDialogProps> = ({open, packingOption, onCancel}) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const deletePackingOption = useStore((state) => state.packFirstBoxingActions.deletePackingOption);
    const {currentUser} = useAuth();
    const queryClient = useQueryClient();
    const handleDelete = async () => {
        if (!packingOption) return;
        try {
            setIsDeleting(true);
            await deletePackingOption(packingOption.packingOptionId, true);
            queryClient.refetchQueries({queryKey: ["batches", currentUser?.uid]});
            queryClient.refetchQueries({queryKey: ["shipment_planning_batches", currentUser?.uid]});
            onCancel();
        } finally {
            setIsDeleting(false);
        }
    };

    if (!packingOption) return null;

    return (
        <Modal
            title="Delete Packing Option"
            open={open}
            footer={null}
            onCancel={onCancel}
            closable={!isDeleting}
            maskClosable={!isDeleting}
        >
            <Space direction="vertical" style={{width: "100%"}}>
                <Text>Are you sure you want to delete this packing option?</Text>
                <Text>It will restore the old batch and delete the current one with all its products.</Text>

                <Space style={{width: "100%", justifyContent: "end", marginTop: 16}}>
                    <Button onClick={onCancel} disabled={isDeleting}>
                        Cancel
                    </Button>
                    <Button type="primary" danger onClick={handleDelete} loading={isDeleting} disabled={isDeleting}>
                        Delete Packing Option
                    </Button>
                </Space>
            </Space>
        </Modal>
    );
};

export default DeletePackingOptionDialog;
