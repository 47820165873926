import {Card, Col, Row, Statistic, Modal, Form, Input, Select, Divider, message, Tag} from "antd";
import React, {useState, useEffect, useCallback, Fragment} from "react";
import {EditOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {useSupplierContext} from "../../contexts/SupplierContext";
import {
    POSSIBLE_BRAND_STATUSES,
    POSSIBLE_GROWTH_PLANS,
    POSSIBLE_MAP_BREAKS,
    POSSIBLE_PAYMENT_METHODS,
    POSSIBLE_PAYMENT_TERMS,
    SUPPLIER_MANAGERS,
    SUPPLIER_USERS,
    SupplierDataValues,
} from "../../types/OmegaTypes";
import "./values/styles.css";
import {valueType} from "antd/es/statistic/utils";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const BRAND_VALUES_FORMATS: {
    [key in keyof SupplierDataValues]: any;
} = {
    brandStatus: {span: 4},
    brandManager: {span: 4},
    brandStrategist: {span: 4},
    brandStakeholders: {span: 4},
    salesRep: {span: 4},
    brandChannel: {span: 4},

    monthlyRevenue: {span: 4},
    ourRevenue: {span: 4},
    goalRevenue: {span: 4},
    growthPlan: {span: 4},
    isNegotiable: {span: 4},
    prepDelay: {span: 4},

    isDistributor: {span: 3},
    isExclusive: {span: 3},
    mapPolicyValue: {span: 3},
    b2bMapPolicy: {span: 3},
    couponable: {span: 4},
    paymentMethod: {span: 4},
    paymentTerms: {span: 4},
    isDistributorText: {
        span: 3,
        height: "100px",
    },
    isExclusiveText: {
        span: 3,
        height: "100px",
    },
    mapPolicyValueText: {
        span: 3,
        height: "100px",
    },
    b2bMapPolicyText: {
        span: 3,
        height: "100px",
    },
    couponableText: {
        span: 4,
        height: "100px",
    },
    paymentMethodText: {
        span: 4,
        height: "100px",
    },
    paymentTermsText: {
        span: 4,
        height: "100px",
    },

    bookingWindows: {
        span: 6,
        height: "100px",
    },
    bookingRevisionPolicy: {
        span: 6,
        height: "100px",
    },
    atsGuide: {
        span: 6,
        height: "100px",
    },
    orderNotes: {
        span: 6,
        height: "100px",
    },

    orderForm: {
        span: 6,
        height: "100px",
    },
    priceList: {
        span: 6,
        height: "100px",
    },
    discountRules: {
        span: 6,
        height: "100px",
    },
    shippingNotes: {
        span: 6,
        height: "100px",
    },

    // prepCost: {span: 4},
    // shippingCost: { span: 6 },
    // returnRate: { span: 6 },
};

const BRAND_VALUES_FORMATS_EXPERIMENTAL: {
    [key in keyof SupplierDataValues]: any;
} = {
    ...BRAND_VALUES_FORMATS,
    growthPlan: {span: 2},
    mapPolicyValue: {span: 2},
    mapPolicyValueText: {
        span: 5,
    },
    discountRules: {
        span: 5,
    },
    paymentTerms: {span: 4},
    paymentMethod: {span: 3},
    prepDelay: {span: 3},
};

const BRAND_VALUES_LABELS: {
    [key in keyof SupplierDataValues]: string;
} = {
    // span of 8
    brandStatus: "Brand Status",
    brandManager: "Lead Buyer",
    brandStrategist: "Brand Strategists",
    brandStakeholders: "Brand Stakeholders",
    salesRep: "Sales Rep",
    brandChannel: "Brand Channel",

    // span of 6
    monthlyRevenue: "Monthly Revenue",
    ourRevenue: "Our Revenue",
    goalRevenue: "Goal Revenue",
    growthPlan: "Growth Plan",
    isNegotiable: "Is Negotiable?",
    prepDelay: "Prep Delay",

    // span of 4
    isDistributor: "Is Distributor?",
    isExclusive: "Is Exclusive?",
    mapPolicyValue: "MAP Policy",
    b2bMapPolicy: "B2B Map Policy",
    couponable: "Couponable?",
    paymentMethod: "Payment Method",
    paymentTerms: "Payment Terms",
    isDistributorText: "(Text) Is Distributor?",
    isExclusiveText: "(Text) Is Exclusive?",
    mapPolicyValueText: "MAP Policy Details",
    b2bMapPolicyText: "(Text) B2B Map Policy",
    couponableText: "(Text) Couponable?",
    paymentMethodText: "(Text) Payment Method",
    paymentTermsText: "(Text) Payment Terms",

    // span of 6
    bookingWindows: "Booking Windows",
    bookingRevisionPolicy: "Booking Revision Policy",
    atsGuide: "How to get ATS?",
    orderNotes: "Order Notes",

    // span of 6
    orderForm: "Order Form",
    priceList: "Price List",
    discountRules: "Discount Rules",
    shippingNotes: "Shipping Notes",

    // span of 6
    // prepCost: "Prep Cost",
    // shippingCost: "Shipping Cost",
    // returnRate: "Return Rate",
};

export const BRAND_DEFAULT_VALUES: SupplierDataValues = {
    brandStatus: ["Open"],
    brandManager: "",
    brandStrategist: [],
    brandStakeholders: [],
    salesRep: "",
    brandChannel: "",

    monthlyRevenue: 0,
    ourRevenue: 0,
    goalRevenue: 0,
    growthPlan: "Maximize",
    isNegotiable: false,

    isDistributor: false,
    isExclusive: false,
    mapPolicyValue: ["No MAP"],
    b2bMapPolicy: false,
    couponable: false,
    paymentMethod: "",
    paymentTerms: "",
    isDistributorText: "",
    isExclusiveText: "",
    mapPolicyValueText: "",
    b2bMapPolicyText: "",
    couponableText: "",
    paymentMethodText: "",
    paymentTermsText: "",

    bookingWindows: "",
    bookingRevisionPolicy: "",
    atsGuide: "",
    orderNotes: "",

    orderForm: "",
    priceList: "",

    discountRules: "",
    shippingNotes: "",

    prepDelay: 0,
    // prepCost: 0,
    // shippingCost: 0,
    // returnRate: 0,
};

const CreateValueDisplay = (
    supplierDataValues: SupplierDataValues,
    editValue: (dataIndex: keyof SupplierDataValues) => void,
    layout: string = "default"
) => {
    let currentSpanCount = 0;
    let valueSubset = Object.entries(BRAND_VALUES_LABELS);
    const formatsToUse = JSON.parse(JSON.stringify(layout === "experimental" ? BRAND_VALUES_FORMATS_EXPERIMENTAL : BRAND_VALUES_FORMATS));

    if (layout === "experimental") {
        const subset = [
            "brandStatus",
            "brandManager",
            "brandStrategist",
            "brandStakeholders",
            "salesRep",
            "brandChannel",
            "growthPlan",
            "mapPolicyValue",
            "mapPolicyValueText",
            "discountRules",
            "paymentMethod",
            "paymentTerms",
            "prepDelay",
        ];

        valueSubset = subset.map((key) => [key, BRAND_VALUES_LABELS[key as keyof SupplierDataValues]]);
        Object.values(formatsToUse as any as SupplierDataValues).forEach((value, idx) => {
            value.height = "100px";
        });
    }

    return valueSubset.map(([dataIndex, label], idx) => {
        let currIndex = dataIndex as keyof SupplierDataValues;
        let currentColNode = (
            <Col key={`${idx}-${dataIndex}`} span={formatsToUse[currIndex].span}>
                <Card
                    bordered={true}
                    title={<span style={{color: "#ec7339", fontWeight: 600}}>{label}</span>}
                    extra={<EditOutlined onClick={() => editValue(currIndex)} />}
                >
                    <Statistic
                        className="value-statistic"
                        value={supplierDataValues[currIndex] as valueType}
                        valueStyle={{
                            overflowY: "auto",
                            overflowWrap: "break-word",
                            maxWidth: "100%",
                            height: formatsToUse[currIndex].height ?? null,
                        }}
                        formatter={(value) => {
                            if (typeof value === "boolean") {
                                return value ? "Yes" : "No";
                            } else if (typeof value === "string") {
                                return (
                                    <Markdown className={"markdown-div"} remarkPlugins={[remarkGfm]}>
                                        {value}
                                    </Markdown>
                                );
                            } else if (Array.isArray(value)) {
                                return value.map((val, tagIdx) => (
                                    <Tag style={{fontSize: "medium", padding: "6px"}} key={`${idx}-${dataIndex}-${tagIdx}`}>
                                        {val}
                                    </Tag>
                                ));
                            }

                            return value.toString();
                        }}
                    />
                </Card>
            </Col>
        );

        currentSpanCount += formatsToUse[currIndex].span;
        if (currentSpanCount === 24) {
            currentSpanCount = 0;
            return (
                <Fragment key={`${idx}-${dataIndex}-fragment`}>
                    {currentColNode}
                    <Col span={24} key={`${idx}-${dataIndex}-divider`}>
                        <Divider type="horizontal"></Divider>
                    </Col>
                </Fragment>
            );
        } else {
            return currentColNode;
        }
    });
};

const GetBrandValuesLabel = (dataIndex: keyof SupplierDataValues) => {
    return BRAND_VALUES_LABELS[dataIndex];
};

const GetBrandValuesEditInput = (dataIndex: keyof SupplierDataValues) => {
    switch (dataIndex) {
        case "isDistributor":
            return (
                <Select>
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                </Select>
            );
        case "isExclusive":
            return (
                <Select>
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                </Select>
            );
        // case "mapPolicyValue":
        //     return (
        //         <Select>
        //             <Select.Option value={true}>Yes</Select.Option>
        //             <Select.Option value={false}>No</Select.Option>
        //         </Select>
        //     );
        case "b2bMapPolicy":
            return (
                <Select>
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                </Select>
            );
        case "couponable":
            return (
                <Select>
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                </Select>
            );
        case "isNegotiable":
            return (
                <Select>
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                </Select>
            );
        case "paymentMethod":
            return (
                <Select>
                    {POSSIBLE_PAYMENT_METHODS.map((method, idx) => (
                        <Select.Option key={`1-${idx}`} value={method}>
                            {method}
                        </Select.Option>
                    ))}
                </Select>
            );
        case "growthPlan":
            return (
                <Select>
                    {POSSIBLE_GROWTH_PLANS.map((method, idx) => (
                        <Select.Option key={`5-${idx}`} value={method}>
                            {method}
                        </Select.Option>
                    ))}
                </Select>
            );
        case "paymentTerms":
            return (
                <Select>
                    {POSSIBLE_PAYMENT_TERMS.map((method, idx) => (
                        <Select.Option key={`2-${idx}`} value={method}>
                            {method}
                        </Select.Option>
                    ))}
                </Select>
            );
        case "brandStrategist":
            return (
                <Select mode="multiple">
                    {SUPPLIER_USERS.map((method, idx) => (
                        <Select.Option key={`6-${idx}`} value={method}>
                            {method}
                        </Select.Option>
                    ))}
                </Select>
            );
        case "brandStakeholders":
            return (
                <Select mode="multiple">
                    {[...SUPPLIER_USERS, ...SUPPLIER_MANAGERS].map((method, idx) => (
                        <Select.Option key={`3-${idx}`} value={method}>
                            {method}
                        </Select.Option>
                    ))}
                </Select>
            );
        case "brandManager":
            return (
                <Select>
                    {SUPPLIER_MANAGERS.map((method, idx) => (
                        <Select.Option key={`4-${idx}`} value={method}>
                            {method}
                        </Select.Option>
                    ))}
                </Select>
            );
        case "mapPolicyValue":
            return (
                <Select mode="tags">
                    {POSSIBLE_MAP_BREAKS.map((method, idx) => (
                        <Select.Option key={`7-${idx}`} value={method}>
                            {method}
                        </Select.Option>
                    ))}
                </Select>
            );
        case "brandStatus":
            return (
                <Select mode="tags">
                    {POSSIBLE_BRAND_STATUSES.map((method, idx) => (
                        <Select.Option key={`8-${idx}`} value={method}>
                            {method}
                        </Select.Option>
                    ))}
                </Select>
            );
        case "bookingWindows":
        case "bookingRevisionPolicy":
        case "atsGuide":
        case "orderNotes":
        case "orderForm":
        case "priceList":
        case "discountRules":
        case "shippingNotes":
        case "isDistributorText":
        case "isExclusiveText":
        case "mapPolicyValueText":
        case "b2bMapPolicyText":
        case "couponableText":
        case "paymentMethodText":
        case "paymentTermsText":
            return <Input.TextArea rows={4} />;
        default:
            return <Input title={`New ${dataIndex}`} />;
    }
};

const BrandValues: React.FC<{valuesLayout: string}> = ({valuesLayout}) => {
    const {selectedSupplier: supplier, updateSupplier} = useSupplierContext();
    const [modal, contextHolder] = Modal.useModal();
    const [supplierDataValues, setSupplierDataValues] = useState<SupplierDataValues>(BRAND_DEFAULT_VALUES);
    const [tagForm] = Form.useForm();

    useEffect(() => {
        setSupplierDataValues({
            ...BRAND_DEFAULT_VALUES,
            ...(supplier?.dataValues ?? {}),
        });
    }, [supplier?.dataValues]);

    useEffect(() => {
        if (tagForm) {
            tagForm.setFieldsValue(supplierDataValues);
        }
    }, [tagForm, supplierDataValues]);

    const editValue = useCallback(
        (dataIndex: keyof SupplierDataValues) => {
            modal.confirm({
                title: `Fill in the values for this action`,
                icon: <ExclamationCircleOutlined />,
                content: (
                    <Form form={tagForm} name={`edit-${dataIndex}`} layout="vertical" preserve={true}>
                        <Form.Item name={dataIndex} key={dataIndex} label={`What should we set the ${GetBrandValuesLabel(dataIndex)} to?`}>
                            {GetBrandValuesEditInput(dataIndex)}
                        </Form.Item>
                    </Form>
                ),
                onOk() {
                    tagForm.validateFields().then((values) => {
                        console.log("bulk form values", values);
                        if (values[dataIndex] !== undefined) {
                            updateSupplier(
                                {
                                    name: supplier!.name,
                                    dataValues: {
                                        ...supplierDataValues,
                                        [dataIndex]: values[dataIndex],
                                    },
                                },
                                "values",
                                GetBrandValuesLabel(dataIndex),
                                `*${GetBrandValuesLabel(dataIndex)}* has been updated to: ${
                                    values[dataIndex]?.length > 100 ? values[dataIndex].slice(0, 100) + "..." : values[dataIndex]
                                }`
                            );

                            tagForm.setFieldsValue({
                                ...supplierDataValues,
                                [dataIndex]: values[dataIndex],
                            });
                        } else {
                            message.error("Please fill in the form");
                        }
                    });
                },
                onCancel() {
                    tagForm.setFieldsValue({...supplierDataValues});
                },
            });
        },
        [modal, tagForm, supplier, updateSupplier, supplierDataValues]
    );

    return (
        <>
            {contextHolder}
            <Row gutter={[8, 0]} key={"xdd-row"}>
                {CreateValueDisplay(supplierDataValues, editValue, valuesLayout)}
            </Row>
        </>
    );
};

export default BrandValues;
