import React, {useState} from "react";
import {Modal, Upload, notification, Button} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import type {UploadProps} from "antd";
import type {RcFile} from "antd/es/upload";
import {useAuth} from "../../../contexts/AuthContext";
import {processInvoicePDF} from "../../../services/WholesaleService";
import {Invoice, InvoiceIdMatch} from "../../../types/OmegaTypes";

const {Dragger} = Upload;

interface UploadInvoiceDialogProps {
    open: boolean;
    items: {sku: string; name: string; price?: number}[];
    onCancel: () => void;
    onComplete: (invoice: Invoice, csv: string, matches: InvoiceIdMatch[], callId: string) => void;
}

export const UploadInvoiceDialog: React.FC<UploadInvoiceDialogProps> = ({open, items, onCancel, onComplete}) => {
    const {currentUser} = useAuth();
    const [uploading, setUploading] = useState(false);
    const [selectedFile, setSelectedFile] = useState<RcFile | null>(null);

    const handleUpload = async () => {
        if (!selectedFile) return;

        try {
            setUploading(true);
            const token = await currentUser!.getIdToken();
            const result = await processInvoicePDF(token, selectedFile, items);

            onComplete(result.invoice, result.csv, result.id_matches, result.call_id);
            onCancel();
            notification.success({
                message: "Success",
                description: "Invoice processed successfully",
            });
        } catch (error) {
            notification.error({
                message: "Error",
                description: error instanceof Error ? error.message : "Failed to process invoice",
            });
        } finally {
            setUploading(false);
        }
    };

    const uploadProps: UploadProps = {
        name: "file",
        multiple: false,
        accept: ".pdf",
        maxCount: 1,
        showUploadList: true,
        beforeUpload: (file) => {
            setSelectedFile(file);
            return false;
        },
        onRemove: () => {
            setSelectedFile(null);
        },
    };

    return (
        <Modal
            title="Upload Invoice PDF"
            open={open}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel} disabled={uploading}>
                    Cancel
                </Button>,
                <Button key="upload" type="primary" onClick={handleUpload} disabled={!selectedFile || uploading} loading={uploading}>
                    Upload Invoice
                </Button>,
            ]}
        >
            <Dragger {...uploadProps} disabled={uploading}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag invoice PDF to upload</p>
                <p className="ant-upload-hint">Only PDF files are supported</p>
                <p className="ant-upload-hint">It may take about 20 seconds per page to process the invoice</p>
            </Dragger>
        </Modal>
    );
};
