import React, {useEffect, useState} from "react";
import {Modal, Select, Form, notification, Row, Col, Card, Typography, List, Space, Descriptions} from "antd";
import {WarehouseProduct} from "../../../types/WarehouseTypes";
import {setPrepDetails, waitForOperation} from "../../../services/SPApiService";

interface PrepDetailsDialogProps {
    open: boolean;
    onClose: () => void;
    item?: WarehouseProduct;
}
export interface PrepGuidance {
    category: string;
    categoryLabel: string;
    productDescription: string;
    examples: string;
    prepGuidance: string[];
}

export const prepGuidanceData: PrepGuidance[] = [
    {
        category: "ADULT",
        categoryLabel: "Adult",
        productDescription: "Displays potentially offensive material such as profanity or nudity",
        examples: "Products that contain pictures of live, nude models, packaging that has obscene or profane messaging",
        prepGuidance: [
            "Place product in a black or opaque bag.",
            "Bag must have a suffocation warning.",
            "Bag must be secured if not self-adhesive.",
            "Barcode must be scannable.",
        ],
    },
    {
        category: "HANGER",
        categoryLabel: "Apparel on hanger",
        productDescription: "Made of cloth or fabric and intended to be put on a hanger",
        examples: "Scarves, shirts, jackets",
        prepGuidance: [
            "Remove hanger, and then place product in a transparent poly bag to protect from damage or dust.",
            "Products can also be shrink-wrapped.",
            "Bag must have a suffocation warning.",
            "Bag must be secured if not self-adhesive.",
            "Barcode must be scannable.",
        ],
    },
    {
        category: "TEXTILE",
        categoryLabel: "Apparel, fabric, plush, and textiles",
        productDescription: "Made of cloth or fabric that could be damaged by dirt, dust, moisture, or liquid",
        examples: "Shirts, bags, belts, plush toys such as teddy bears",
        prepGuidance: [
            "Place product in a transparent poly bag to protect from damage or dust.",
            "Products can also be shrink-wrapped.",
            "Bag must have a suffocation warning.",
            "Bag must be secured if not self-adhesive.",
            "Barcode must be scannable.",
        ],
    },
    {
        category: "BABY",
        categoryLabel: "Baby",
        productDescription: "Made for a child aged three years or younger, packaging with cutouts greater than one square inch",
        examples: "Teething rings, bibs, exposed toys",
        prepGuidance: [
            "Place product in a transparent poly bag.",
            "Bag must have a suffocation warning.",
            "Bag must be secured if not self-adhesive.",
            "Barcode must be scannable.",
        ],
    },
    {
        category: "FRAGILE",
        categoryLabel: "Fragile, glass",
        productDescription: "Glass or otherwise fragile, or a liquid in a glass container",
        examples: "Glass, china, picture frames, clocks, mirrors, bottles of olive oil",
        prepGuidance: [
            "Place product in bubble wrap or a bubble bag to prevent damage. Products can also be placed in a protective overbox.",
            "Product bag or box must be secured or taped.",
            "Product can pass a drop test on a hard surface without breaking.",
            "Barcode must be scannable.",
        ],
    },
    {
        category: "LIQUID",
        categoryLabel: "Liquids (non-glass)",
        productDescription: "Liquid or viscous without a double seal",
        examples: "soap, spray bottles, lotion, honey",
        prepGuidance: [
            "Place product in a transparent poly bag to protect from leakage.",
            "Bag must have a suffocation warning.",
            "Bag must be secured if not self-adhesive.",
            "Barcode must be scannable.",
        ],
    },
    {
        category: "PERFORATED",
        categoryLabel: "Perforated packaging",
        productDescription: "In packaging that has a perforated opening",
        examples: "Chocolate boxes, candy boxes",
        prepGuidance: [
            "Seal product in a transparent poly bag. The poly bag must include a suffocation warning.",
            "Barcode must be scannable without having to open or unwrap.",
        ],
    },
    {
        category: "GRANULAR",
        categoryLabel: "Powder, pellets, and granular",
        productDescription: "Made of powder, pellets, or granular material",
        examples: "Facial powder, sugar, powdered detergents",
        prepGuidance: [
            "Seal product in a transparent poly bag. The poly bag must include a suffocation warning.",
            "Barcode must be scannable without having to open or unwrap the unit.",
        ],
    },
    {
        category: "SHARP",
        categoryLabel: "Sharp",
        productDescription: "Sharp and easily exposed, not already contained in protective packaging",
        examples: "Scissors, tools, metal raw materials",
        prepGuidance: [
            "Place product in bubble wrap or a bubble bag to keep it from being exposed. Products can also be placed in a protective overbox.",
            "Ensure that the packaging is secured and that any sharp points or edges are completely covered.",
            "Barcode must be scannable.",
        ],
    },
    {
        category: "SMALL",
        categoryLabel: "Small",
        productDescription: "Longest side less than 2 1/8 inches (width of a credit card)",
        examples: "Jewelry, keychains, flash drives",
        prepGuidance: [
            "Place product in a transparent poly bag.",
            "Bag must have a suffocation warning.",
            "Bag must be secured if not self-adhesive.",
            "Barcode must be scannable.",
        ],
    },
    {
        category: "SET",
        categoryLabel: "Sold as set",
        productDescription: "Multiple items that are sold as one unit",
        examples: "Bundled items, Multi-packs, Toy sets, Case of bottled drinks",
        prepGuidance: [
            'Add a label to the unit that clearly states that the products are to be received and sold as a single unit. For example, "Sold as set," "Ready to ship," or "This is a set. Do not separate."',
            "Provide a unique ASIN for the set if the individual units in the set already have an ASIN.",
            "The barcodes on the individual units must not face outward or the barcodes must be covered.",
            "The set's barcode must be scannable.",
        ],
    },
    {
        category: "NONE",
        categoryLabel: "No prep needed",
        productDescription: "Does not require prep",
        examples: "Books, DVDs, video games",
        prepGuidance: ["Units might still require labeling"],
    },
    {
        category: "UNKNOWN",
        categoryLabel: "Unknown",
        productDescription: "Prep requirements cannot be determined",
        examples: "New or unusual items",
        prepGuidance: ["Contact Seller Support for guidance on prep requirements"],
    },
    {
        category: "FC_PROVIDED",
        categoryLabel: "FBA Prep Service",
        productDescription: "Prep service provided by Amazon",
        examples: "Any eligible items",
        prepGuidance: ["No prep required - Amazon will handle preparation"],
    },
];

const prepCategories: string[] = [
    "ADULT",
    "BABY",
    "FC_PROVIDED",
    "FRAGILE",
    "GRANULAR",
    "HANGER",
    "LIQUID",
    "PERFORATED",
    "SET",
    "SHARP",
    "SMALL",
    "TEXTILE",
    "UNKNOWN",
    "NONE",
];

const prepTypes: string[] = [
    "ITEM_BLACK_SHRINKWRAP",
    "ITEM_BLANKSTK",
    "ITEM_BOXING",
    "ITEM_BUBBLEWRAP",
    "ITEM_CAP_SEALING",
    "ITEM_DEBUNDLE",
    "ITEM_HANG_GARMENT",
    "ITEM_LABELING",
    "ITEM_NO_PREP",
    "ITEM_POLYBAGGING",
    "ITEM_RMOVHANG",
    "ITEM_SETCREAT",
    "ITEM_SETSTK",
    "ITEM_SIOC",
    "ITEM_SUFFOSTK",
    "ITEM_TAPING",
];

const {Text} = Typography;

const PrepDetailsDialog: React.FC<PrepDetailsDialogProps> = ({open, onClose, item}) => {
    const [loading, setLoading] = useState(false);
    const [selectedGuidance, setSelectedGuidance] = useState<PrepGuidance | null>(null);
    const [form] = Form.useForm();

    const handleCategoryChange = (value: string) => {
        const guidance = prepGuidanceData.find((g) => g.category === value);
        setSelectedGuidance(guidance || null);
    };

    const handleSubmit = async () => {
        if (!item?.sku) {
            notification.error({
                message: "Error",
                description: "No SKU found for this item",
            });
            return;
        }

        try {
            setLoading(true);
            const values = await form.validateFields();

            const res = await setPrepDetails({
                marketplaceId: "ATVPDKIKX0DER",
                mskuPrepDetails: [
                    {
                        msku: item.sku,
                        prepCategory: values.prepCategory,
                        prepTypes: values.prepTypes,
                    },
                ],
            });

            const waitRes = await waitForOperation(res.operationId, 2000);
            if (waitRes.operationStatus === "SUCCESS") {
                notification.success({
                    message: "Success",
                    description: "Prep details updated successfully",
                });
                onClose();
            } else {
                notification.error({
                    message: "Error",
                    description: waitRes.operationProblems?.map((problem) => `${problem.message} (${problem.details})`).join(" | "),
                });
            }
        } catch (error: any) {
            notification.error({
                message: "Error",
                description: error.message || "Failed to update prep details",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!open) {
            setSelectedGuidance(null);
        }
    }, [open]);

    return (
        <Modal
            title="Set Prep Details"
            open={open}
            onCancel={onClose}
            onOk={handleSubmit}
            confirmLoading={loading}
            destroyOnClose
            width={1000}
        >
            <Row gutter={16}>
                <Col span={8}>
                    <Form form={form} layout="vertical" preserve={false}>
                        <Form.Item
                            name="prepCategory"
                            label="Prep Category"
                            rules={[{required: true, message: "Please select a prep category"}]}
                        >
                            <Select onChange={handleCategoryChange}>
                                {prepCategories.map((category) => (
                                    <Select.Option key={category} value={category}>
                                        {category.replace(/_/g, " ")}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="prepTypes"
                            label="Prep Types"
                            rules={[{required: true, message: "Please select at least one prep type"}]}
                        >
                            <Select mode="multiple">
                                {prepTypes.map((type) => (
                                    <Select.Option key={type} value={type}>
                                        {type.replace(/^ITEM_/g, "").replace(/_/g, " ")}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={16}>
                    {selectedGuidance ? (
                        <Space direction="vertical" size="middle" style={{width: "100%"}}>
                            <Descriptions
                                column={1}
                                bordered
                                items={[
                                    {
                                        key: "category",
                                        label: "Category",
                                        children: selectedGuidance.categoryLabel,
                                    },
                                    {
                                        key: "description",
                                        label: "Description",
                                        children: selectedGuidance.productDescription,
                                    },
                                    {
                                        key: "examples",
                                        label: "Examples",
                                        children: selectedGuidance.examples,
                                    },
                                    {
                                        key: "guidelines",
                                        label: "Preparation Guidelines",
                                        children: (
                                            <List
                                                size="small"
                                                dataSource={selectedGuidance.prepGuidance}
                                                renderItem={(item) => (
                                                    <List.Item>
                                                        <Text>{item}</Text>
                                                    </List.Item>
                                                )}
                                            />
                                        ),
                                    },
                                ]}
                            />
                        </Space>
                    ) : (
                        <Card>
                            <Text type="secondary">Select a prep category to view preparation guidelines</Text>
                        </Card>
                    )}
                </Col>
            </Row>
        </Modal>
    );
};

export default PrepDetailsDialog;
