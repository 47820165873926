import React, {useEffect, useMemo} from "react";
import {InboundPackingOption} from "../../../types/WarehouseTypes";
import {Button, Space, Spin, Tabs, Typography} from "antd";
import {useStore} from "../../../store/useStore";
import {useShallow} from "zustand/react/shallow";
import {TabsProps} from "antd/lib";
import PackingGroupView from "./PackingGroupView";

interface PackingOptionViewProps {
    option?: InboundPackingOption;
}

const PackingOptionView: React.FC<PackingOptionViewProps> = ({option}) => {
    const {data, actions} = useStore(
        useShallow((state) => ({
            data: state.packFirstBoxingData,
            actions: state.packFirstBoxingActions,
        }))
    );

    const packingGroups = useMemo(
        () => Object.values(data.packingGroups.byId).filter((pg) => pg.inboundPlanId === option?.inboundPlanId),
        [data.packingGroups, option]
    );

    useEffect(() => {
        if (!option) {
            return;
        }
        // Make sure that the active packing group is always a valid packing group
        if (!option?.activePackingGroupId || !packingGroups.find((pg) => pg.packingGroupId === option.activePackingGroupId)) {
            actions.setActivePackingGroupId(option.packingOptionId, packingGroups[0].packingGroupId);
        }
    }, [option, packingGroups, actions]);

    const tabItems: TabsProps["items"] = useMemo(() => {
        const items: TabsProps["items"] = packingGroups.map((pg, index) => ({
            key: pg.packingGroupId,
            label: `Group ${index + 1}`,
            children: (
                <PackingGroupView packingGroup={pg} disabled={option?.confirmTransportationOptionsStatus?.operationStatus === "SUCCESS"} />
            ),
        }));
        return items;
    }, [packingGroups, option]);

    if (!option) {
        return <Typography.Title level={4}>No option selected</Typography.Title>;
    }

    const packingInformationTimestamp = option.packingInformationTimestamp || 0;
    const lastUpdateTimestamp = option.lastUpdate || 0;
    const loadingPackingOption = data.loadingPackingOptions.find((o) => o.id === option.packingOptionId);

    return (
        <Spin spinning={!!loadingPackingOption} tip={loadingPackingOption?.message}>
            <Space direction="vertical" style={{width: "100%"}}>
                <Space style={{width: "100%", marginTop: 10}}>
                    <Button
                        type="primary"
                        onClick={() => {
                            actions.submitBoxesAndFetchShipments(option.packingOptionId);
                        }}
                        disabled={option?.confirmTransportationOptionsStatus?.operationStatus === "SUCCESS"}
                    >
                        Submit
                    </Button>
                    <Button
                        type="primary"
                        disabled={
                            option.packingInformationStatus?.operationStatus !== "SUCCESS" ||
                            packingInformationTimestamp < lastUpdateTimestamp ||
                            option?.confirmTransportationOptionsStatus?.operationStatus === "SUCCESS"
                        }
                        onClick={() => {
                            actions.generatePlacementOptions(option.packingOptionId);
                        }}
                    >
                        Generate placement options
                    </Button>
                </Space>
                <Tabs
                    activeKey={option.activePackingGroupId}
                    items={tabItems}
                    onChange={(key) => actions.setActivePackingGroupId(option.packingOptionId, key)}
                />
            </Space>
        </Spin>
    );
};

export default PackingOptionView;
