import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Button, Col, Divider, Input, InputRef, Modal, notification, Row, Space, Switch} from "antd";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import {getItemizedFreightLogEntry, updateFreightLogItemized} from "../../../services/WholesaleService";
import {FreightLogItemizedEntry, FreightLogItemizedEntryRenderType, Invoice, InvoiceIdMatch} from "../../../types/OmegaTypes";
import {freightLogItemizedEntryTransformation} from "../../utilities/OMSCalculations";
import {UploadInvoiceDialog} from "./UploadInvoiceDialog";
import {makeColumnsEditable, makeFreightLogItemizedEntryColumns} from "../../utilities/OMSColumns";
import {validateFreightLogEntry} from "../../utilities/TableDataValidation";
import {UnsavedChangesHandler} from "../../utilities/UnsavedChangesHandler";
import {
    BulkActionsButton,
    BulkActionType,
    useBoolColumnModal,
    useDateColumnModal,
    useNumberColumnModal,
    useTextColumnModal,
} from "../table/BulkActions";
import {EditableColumnType} from "../table/EditableCell";
import EditableTable, {EditableTableHandle} from "../table/EditableTable";
import {FreightLogItemizedRow} from "../../../types/OmegaTypes";
import {
    BookOutlined,
    DeleteFilled,
    DollarOutlined,
    NumberOutlined,
    PullRequestOutlined,
    RollbackOutlined,
    ShopOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import {CSVDownload} from "../../utilities/CSVDownload";
import dayjs from "dayjs";
import * as dataForge from "data-forge";
import * as _ from "lodash";
import {UploadHandler} from "../../utilities/UploadHandler";
import {InvoiceValidationDialog} from "./InvoiceValidationDialog";

const defaultItem: FreightLogItemizedEntry = {
    _id: undefined,
    Supplier_Name: "",
    Supplier_PO: undefined,
    Supplier_SO: undefined,
    Supplier_Title: "",
    Supplier_SKU: "",
    UPC: "",
    Quantity: 0,
    Booked: false,
    ShippedTo: "",
    ProductId: "",
    Ship_Requested: new Date(),
    ShipDateBooked: undefined,
    ItemsNum: undefined,
    UnitsShipped: undefined,
    UnitsBackordered: undefined,
    TotalCost: 0,
    UnitCost: undefined,
    ShipmentPlanFile: undefined,
    Timestamp: new Date(),
    ShipmentTracker: 1,
};

const acceptedColumns: (keyof FreightLogItemizedEntry)[] = [
    "Supplier_Title",
    "UPC",
    "Supplier_SKU",
    "Quantity",
    "Supplier_Name",
    "Supplier_PO",
    "Supplier_SO",
    "ShipmentTracker",
    "BolId",
    "ProductId",
    "Ship_Requested",
    "ShipDateBooked",
    "UnitsShipped",
    "UnitsBackordered",
    "BackorderDate",
    "TotalCost",
    "UnitCost",
    "ShippedTo",
    "Booked",
    "ShipmentPlanFile",
];

export const FreightLogItemizedEntryPage: React.FC = () => {
    const {currentUser} = useAuth();
    const queryClient = useQueryClient();
    const searchInputRef = useRef<InputRef>(null);
    const [tableData, setTableData] = useState<FreightLogItemizedEntryRenderType[]>([]);
    const [fetchTimestamp, setFetchTimestamp] = useState<Date>(new Date());
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<Date>(fetchTimestamp);
    const [editable, setEditable] = useState(false);
    const posInputRef = useRef<InputRef>(null);
    const [pos, setPos] = useState<string | undefined>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const tableRef = useRef<EditableTableHandle<FreightLogItemizedEntryRenderType>>(null);
    const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
    const [invoiceValidationDialogOpen, setInvoiceValidationDialogOpen] = useState(false);
    const [invoiceData, setInvoiceData] = useState<
        | {
              invoice: Invoice;
              callId: string;
          }
        | undefined
    >();

    const [numberModal, numberModalContext] = useNumberColumnModal(tableData, selectedRowKeys);
    const [dateModal, dateModalContext] = useDateColumnModal(tableData, selectedRowKeys);
    const [boolModal, boolModalContext] = useBoolColumnModal(tableData, selectedRowKeys);
    const [textModal, textModalContext] = useTextColumnModal(tableData, selectedRowKeys);

    const {
        data: freightLogData,
        isLoading: freightLogDataLoading,
        isRefetching,
        refetch,
        error,
    } = useQuery({
        queryKey: ["freight_log_itemized", pos],
        queryFn: async () => {
            if (!pos) return [];

            const token = await currentUser!.getIdToken();
            const POs = pos
                .split(",")
                .map((po) => po.trim())
                .filter((po) => po.length > 0);

            if (token && POs.length > 0) {
                let products = await getItemizedFreightLogEntry(token, pos);
                return products;
            }
            return [];
        },

        enabled: false,
        staleTime: Infinity,
        initialData: [],
    });

    useEffect(() => {
        if (error) {
            notification.error({
                message: "Error",
                // @ts-ignore
                description: error.message,
            });
        }
    }, [error]);

    const updateData = (newData: FreightLogItemizedEntry[], timestamp?: Date) => {
        setTableData(
            newData.map((item, idx) => ({
                ...item,
                key: idx,
            }))
        );
        setLastUpdateTimestamp(timestamp || new Date());
    };

    const omsMutation = useMutation({
        mutationFn: async (data: FreightLogItemizedEntryRenderType[]) => {
            const token = await currentUser!.getIdToken();
            const dataToSave: FreightLogItemizedRow[] = data.map(({key, Supplier_Title, UPC, Supplier_SKU, Quantity, ...item}) => ({
                ...item,
                Timestamp: new Date(),
            }));

            await updateFreightLogItemized(token, dataToSave.reverse());
            return data;
        },
        onSuccess: (data) => {
            queryClient.setQueryData(
                ["freight_log_itemized", pos],
                data.map((item, idx) => ({...item, key: idx}))
            );
            setFetchTimestamp(new Date());
            notification.success({
                message: "Success!",
            });
        },
        onError: (error) => {
            notification.error({
                message: "Upload error",
                // @ts-ignore
                description: error.message,
            });
        },
    });

    useEffect(() => {
        refetch();
    }, [pos, refetch]);

    const getPOs = () => {
        let posText = posInputRef.current?.input?.value;
        if (posText) {
            // Remove whitespaces
            posText = posText.replace(/\s/g, "");

            // If the pos string isn't changed, force a refetch
            if (posText !== pos) {
                setPos(posText);
            } else {
                refetch();
            }
        }
    };

    const syncTableData = useCallback(() => {
        if (freightLogData) {
            const newData = freightLogData.map((item, idx) => ({
                ...item,
                key: idx,
            }));
            setTableData(newData);
            setLastUpdateTimestamp(fetchTimestamp);
        }
        // eslint-disable-next-line
    }, [freightLogData]);

    useEffect(() => {
        syncTableData();
    }, [freightLogData, syncTableData]);

    const handleSave = useCallback(
        (row: FreightLogItemizedEntryRenderType, column?: keyof FreightLogItemizedEntryRenderType) => {
            const newData = [...tableData];
            const index = newData.findIndex((item) => row.key === item.key);
            const item = newData[index];
            const newRow = freightLogItemizedEntryTransformation(item, row, column);
            newData.splice(index, 1, {
                ...item,
                ...newRow,
            });
            updateData(newData);
        },
        [tableData]
    );

    const columns = useMemo<EditableColumnType<FreightLogItemizedEntryRenderType>[]>(() => {
        const itemizedEntryColumns = makeFreightLogItemizedEntryColumns(searchInputRef);
        if (editable) {
            itemizedEntryColumns.push({
                title: "Action",
                dataIndex: "",
                key: "x",
                render: (_, record) => (
                    <Button
                        danger
                        icon={<DeleteFilled />}
                        onClick={() => {
                            const dataCopy = [...tableData];
                            const newData = dataCopy.filter((item) => (record._id ? item._id !== record._id : item.key !== record.key));

                            setLastUpdateTimestamp(new Date());
                            setTableData(
                                newData.map((item, idx) => ({
                                    ...item,
                                    key: idx,
                                }))
                            );
                        }}
                    />
                ),
                fixed: "right",
                width: "60px",
            });
            return makeColumnsEditable<FreightLogItemizedEntryRenderType>(itemizedEntryColumns, handleSave);
        } else {
            return itemizedEntryColumns;
        }
    }, [handleSave, editable, tableData]);

    const onSaveChanges = () => {
        if (validateFreightLogEntry(tableData).length === 0) {
            omsMutation.mutate(tableData);
        }
    };

    const bulkActions: BulkActionType[] = [
        {
            label: "Set Units Shipped",
            onClick: () =>
                numberModal.show({
                    columnName: "UnitsShipped",
                    title: "Units Shipped",
                    onUpdate: updateData,
                    onRowUpdated: (row) => {
                        const newRow = {
                            ...row,
                            UnitsShipped: row.UnitsShipped != null ? Math.floor(row.UnitsShipped) : undefined,
                        };
                        return freightLogItemizedEntryTransformation(row, newRow, "UnitsShipped");
                    },
                }),
            icon: <NumberOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: "Set Units Backordered",
            onClick: () =>
                numberModal.show({
                    columnName: "UnitsBackordered",
                    title: "Units Backordered",
                    onUpdate: updateData,
                    onRowUpdated: (row) => ({
                        ...row,
                        UnitsBackordered: row.UnitsBackordered != null ? Math.floor(row.UnitsBackordered) : undefined,
                    }),
                }),
            icon: <RollbackOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: "Set Total Cost",
            onClick: () =>
                numberModal.show({
                    columnName: "TotalCost",
                    title: "Total Cost",
                    onUpdate: updateData,
                    onRowUpdated: (row) => {
                        return freightLogItemizedEntryTransformation(row, row, "TotalCost");
                    },
                }),
            icon: <DollarOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: "Set Shipped To",
            onClick: () =>
                textModal.show({
                    columnName: "ShippedTo",
                    title: "Shipped To",
                    onUpdate: updateData,
                }),
            icon: <ShopOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: "Mark as booked",
            onClick: () =>
                boolModal.show({
                    columnName: "Booked",
                    title: "the value",
                    onUpdate: updateData,
                }),
            icon: <BookOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: "Set Backorder Date",
            onClick: () =>
                dateModal.show({
                    columnName: "BackorderDate",
                    title: "Backorder Date",
                    onUpdate: updateData,
                }),
            icon: <PullRequestOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: "Upload Invoice",
            onClick: () => setInvoiceDialogOpen(true),
            icon: <UploadOutlined />,
            disabled: false,
        },
    ];

    const processData = (data: string) => {
        let dataFrame: dataForge.IDataFrame<number, any> = dataForge.fromCSV(data);

        const columnsDiff = _.difference(["ProductId"], dataFrame.getColumnNames());
        if (columnsDiff.length > 0) {
            notification.error({
                message: "Wrong columns",
                description: (
                    <>
                        Some columns are missing:
                        {columnsDiff.map((col) => (
                            <p style={{margin: 0}}>{col}</p>
                        ))}
                    </>
                ),
            });
            return;
        }
        dataFrame = dataFrame.subset(acceptedColumns);
        dataFrame = dataFrame.parseDates(["Ship_Requested", "ShipDateBooked", "BackorderDate"]);
        dataFrame = dataFrame.transformSeries({
            TotalCost: (val) => (val ? parseFloat(val.replace(/\$/g, "").replace(/,/g, "")) : undefined),
            UnitCost: (val) => (val ? parseFloat(val.replace(/\$/g, "").replace(/,/g, "")) : undefined),
            UnitsShipped: (val) => (val ? parseFloat(val.replace(/,/g, "")) : undefined),
            UnitsBackordered: (val) => (val ? parseFloat(val.replace(/,/g, "")) : undefined),
        });

        const newData = [...tableData];
        const dataMap = Object.fromEntries(newData.map((item) => [item.ProductId || "", item]));

        dataFrame.reverse().forEach(({UPC, ...row}) => {
            row.Booked = row.Booked === "true";
            if (row.ProductId) {
                dataMap[row.ProductId] = {
                    ...dataMap[row.ProductId],
                    ...row,
                };
            }
        });

        console.log(
            dataMap,
            Object.values(dataMap).filter((row) => row.ShipDateBooked !== null)
        );

        setTableData(
            Object.values(dataMap)
                .filter((row) => row.ShipmentPlanFile !== undefined)
                .map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
                .map((item) => freightLogItemizedEntryTransformation(item, item))

            // newData.map((item, idx) => {
            //     const newItem = { ...dataMap[item.ProductId], key: idx };
            //     return freightLogItemizedEntryTransformation(item, newItem);
            // })
        );
        setLastUpdateTimestamp(new Date());
    };

    const tableTitle = () => (
        <Space direction="horizontal" style={{width: "100%", justifyContent: "end"}} split={<Divider type="vertical" />}>
            <UnsavedChangesHandler
                isSaved={lastUpdateTimestamp <= fetchTimestamp}
                isLoading={omsMutation.isPending}
                disableWhenSaved={tableData.length === 0}
                onDiscardChanges={syncTableData}
                onSaveChanges={onSaveChanges}
            />
            <CSVDownload
                collection={`Freight_Log_Itemized_Entry_Export_${dayjs().format("MM-DD-YY")}`}
                data={tableRef.current?.currentData || []}
                isLoading={!tableRef.current}
                dateColumns={["Ship_Requested", "ShipDateBooked", "BackorderDate"]}
                parse={(data) => {
                    const df = new dataForge.DataFrame(data.map((item) => ({...defaultItem, ...item})));
                    return df.subset(acceptedColumns).toArray();
                }}
            />
            <Space>
                Editable: <Switch checked={editable} onChange={setEditable} />
            </Space>
            <Button
                disabled={!editable}
                onClick={() => {
                    Modal.confirm({
                        title: "Remove Empty Rows",
                        content: "Are you sure you want to remove empty rows?",
                        onOk: () => {
                            setTableData((prev) => prev.filter((row) => row.UnitsShipped !== undefined));
                        },
                    });
                }}
            >
                Remove Empty
            </Button>
            <BulkActionsButton actions={bulkActions} />
        </Space>
    );

    const handleInvoiceComplete = (invoice: Invoice, csv: string, matches: InvoiceIdMatch[], callId: string) => {
        const blob = new Blob([csv], {type: "text/comma-separated-values"});
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `parsed_invoice_${dayjs().format("MM-DD-YY_HH-mm")}.csv`;
        link.click();
        // Create a copy of the current table data
        const newData = [...tableData];

        const updateRows = (data: FreightLogItemizedEntryRenderType[], match: InvoiceIdMatch, quantity: number) => {
            const rows = data.filter((row) => row.Supplier_SKU === match.sku);
            let remainingQuantity = quantity;
            rows.forEach((row, idx) => {
                if (remainingQuantity <= 0) return;
                if (remainingQuantity >= row.Quantity) {
                    if (idx === rows.length - 1) {
                        row.UnitsShipped = remainingQuantity;
                        remainingQuantity = 0;
                    } else {
                        row.UnitsShipped = row.Quantity;
                        remainingQuantity -= row.Quantity;
                    }
                } else {
                    row.UnitsShipped = remainingQuantity;
                    remainingQuantity = 0;
                }
            });
        };

        invoice.items.forEach((invoiceItem) => {
            if (invoiceItem.sizes) {
                Object.entries(invoiceItem.sizes).forEach(([size, quantity]) => {
                    if (quantity === 0) return;

                    const match = matches.find(
                        (match) =>
                            match.item_number === invoiceItem.item_number &&
                            match.size === size &&
                            (!match.color || match.color === invoiceItem.color)
                    );
                    if (match) {
                        updateRows(newData, match, quantity);
                    }
                });
            } else {
                const match = matches.find((match) => match.item_number === invoiceItem.item_number && match.color === invoiceItem.color);
                if (match) {
                    updateRows(newData, match, invoiceItem.quantity);
                }
            }
        });

        // Update table with new data
        setTableData(
            newData.map((item, idx) => ({
                ...item,
                key: idx,
            }))
        );
        setLastUpdateTimestamp(new Date());
        setInvoiceDialogOpen(false);
        setInvoiceData({invoice, callId});
        setInvoiceValidationDialogOpen(true);
    };

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <Row>
                <UploadHandler onComplete={processData} template={acceptedColumns} />
            </Row>
            <Row>
                <Col span={22}>
                    <Input placeholder="Enter POs" allowClear ref={posInputRef} />
                </Col>
                <Col span={2}>
                    <Button loading={freightLogDataLoading || isRefetching} onClick={getPOs}>
                        Get
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <EditableTable<FreightLogItemizedEntryRenderType>
                        ref={tableRef}
                        title={tableTitle}
                        tableData={tableData}
                        columns={columns}
                        loading={freightLogDataLoading || isRefetching}
                        onRowSelectionChange={(rowKeys, availableRowKeys) =>
                            setSelectedRowKeys(rowKeys.length === 0 ? availableRowKeys : rowKeys)
                        }
                    />
                </Col>
            </Row>
            {numberModalContext}
            {dateModalContext}
            {textModalContext}
            {boolModalContext}
            <UploadInvoiceDialog
                open={invoiceDialogOpen}
                onCancel={() => setInvoiceDialogOpen(false)}
                onComplete={handleInvoiceComplete}
                // @ts-ignore
                items={tableData.map((item) => ({sku: item.Supplier_SKU, name: item.Supplier_Title, price: item.Cost}))}
            />
            <InvoiceValidationDialog
                open={invoiceValidationDialogOpen}
                invoiceData={invoiceData}
                onConfirm={() => {
                    setInvoiceValidationDialogOpen(false);
                    setInvoiceData(undefined);
                    notification.success({
                        message: "Success!",
                        description: "Invoice validation saved",
                    });
                }}
                onCancel={() => setInvoiceValidationDialogOpen(false)}
            />
        </Space>
    );
};
