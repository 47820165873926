import {useQuery, useQueryClient} from "@tanstack/react-query";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {getReceivedBatches} from "../../../../services/WarehouseService";
import {BatchCategory, WarehouseProduct, batchCategories} from "../../../../types/WarehouseTypes";
import {createInboundPlan, waitForOperation, listPlacementOptions, generatePlacementOptions} from "../../../../services/SPApiService";
import {parseBatches} from "../../common/helpers";
import {Button, Divider, Space, Spin, Tabs, TabsProps, notification} from "antd";
import BatchTable from "../../common/BatchTable";
import {useStore} from "../../../../store/useStore";
import PrintHandler from "../../common/PrintHandler";
import {useAuth} from "../../../../contexts/AuthContext";
import ShipmentPlacementsDialog from "./ShipmentPlacementsDialog";
import {useShallow} from "zustand/react/shallow";

const ShipmentPlanningDashboard: React.FC = () => {
    const {currentUser} = useAuth();
    const {
        printerLoading,
        handlePrintLabels,
        warehouseAddress,
        warehouseContact,
        setPlacementOptions,
        setCurrentInboundPlanId,
        resetReceivingData,
        finishBatch,
        setFinishBatchLoading,
    } = useStore(
        useShallow((state) => ({
            receivingData: state.packLaterReceivingData,
            printerLoading: state.printerLoading,
            handlePrintLabels: state.handlePrint,
            warehouseAddress: state.warehouseAddress,
            warehouseContact: state.warehouseContactInfo,
            setPlacementOptions: state.setPlacementOptions,
            setCurrentInboundPlanId: state.setCurrentInboundPlanId,
            resetReceivingData: state.resetPackLaterReceivingData,
            confirmPlacementOption: state.confirmPlacementOption,
            setFinishBatchLoading: state.setFinishBatchLoading,
            finishBatch: state.packLaterFinishBatch,
        }))
    );
    const [scannedItems, setScannedItems] = useState<{
        [key in BatchCategory]?: WarehouseProduct[];
    }>({HazMat: []});

    const [shipmentPlansLoading, setShipmentPlansLoading] = useState<boolean>(false);
    const [showPlacementOptions, setShowPlacementOptions] = useState<boolean>(false);
    const [currentCategory, setCurrentCategory] = useState<BatchCategory>(batchCategories[0]);
    const queryClient = useQueryClient();

    const {
        data: batches,
        isLoading: batchesLoading,
        isRefetching: batchesRefetching,
    } = useQuery({
        queryKey: ["shipment_planning_batches", currentUser?.uid],
        queryFn: async () => {
            const data = await getReceivedBatches();
            return data;
        },
    });

    useEffect(() => {
        if (batches) {
            const items = parseBatches(batches);
            setScannedItems(items);
        }
    }, [batches]);

    const submitBatch = useCallback(
        async (items: WarehouseProduct[], category: BatchCategory) => {
            setShipmentPlansLoading(true);
            setCurrentCategory(category);
            resetReceivingData();
            try {
                if (!warehouseAddress) {
                    throw new Error("Warehouse address is not set");
                }

                if (!warehouseContact) {
                    throw new Error("Warehouse contact is not set");
                }

                const shipmentName = `${new Date().toLocaleString("en-US")} WISC RAPIDS`;

                const createInboundPlanRes = await createInboundPlan(shipmentName, items, warehouseAddress, warehouseContact, false);
                let waitRes = await waitForOperation(createInboundPlanRes.operationId, 2000, false);
                if (waitRes.operationStatus !== "SUCCESS") {
                    throw new Error("Failed to create inbound plan");
                }
                const inboundPlanId = createInboundPlanRes.inboundPlanId;

                const res = await generatePlacementOptions({inboundPlanId, body: {}}, false);
                waitRes = await waitForOperation(res.operationId, 2000, false);
                if (waitRes.operationStatus !== "SUCCESS") {
                    throw new Error(
                        waitRes.operationProblems?.map((problem) => problem.message).join(" | ") || "Failed to generate placement options"
                    );
                }

                // const placementOptions = await listPlacementOptions(inboundPlanId);
                // const inboundPlanId = "wf9f11d1c3-acda-4723-8356-cc9c2892f180";
                const placementOptions = await listPlacementOptions(inboundPlanId);

                setPlacementOptions(placementOptions);
                setCurrentInboundPlanId(inboundPlanId);
                setShowPlacementOptions(true);
            } catch (error: any) {
                console.log(error);
                notification.error({
                    message: "Error",
                    description: error.message,
                });
            }
            setShipmentPlansLoading(false);
        },
        [warehouseAddress, warehouseContact, setCurrentInboundPlanId, setPlacementOptions, resetReceivingData]
    );

    const finishBatchLocal = async () => {
        setFinishBatchLoading(true);
        try {
            const batch = await finishBatch(scannedItems[currentCategory] || [], currentCategory);
            if (batch) {
                setScannedItems((prev) => ({...prev, [batch.category]: batch.items}));
                setPlacementOptions([]);
                setShowPlacementOptions(false);
            }
            notification.success({
                message: "Success",
                description: "Successfully confirmed placement and finished batch",
            });
            queryClient.refetchQueries({queryKey: ["shipment_planning_batches"]});
            setShowPlacementOptions(false);
        } catch (error: any) {
            notification.error({
                message: "Error",
                description: error.message,
            });
        }
        setFinishBatchLoading(false);
    };

    const tableTitle = useCallback(
        (data: readonly WarehouseProduct[], category: BatchCategory) => (
            <Space direction="horizontal" style={{width: "100%", justifyContent: "start"}} split={<Divider type="vertical" />}>
                <Button
                    onClick={() => {
                        submitBatch(
                            data.map((item) => ({...item})),
                            category
                        );
                    }}
                >
                    Submit
                </Button>
            </Space>
        ),
        [submitBatch]
    );

    const globalIitems: TabsProps["items"] = useMemo(
        () =>
            batchCategories
                .filter((category) => (scannedItems[category] || []).length > 0)
                .map((category) => {
                    const items = scannedItems[category] || [];
                    const units = items.reduce((acc, item) => acc + item.quantity, 0);
                    return {
                        key: category,
                        label: `${category} (${items.length} SKUs, ${units} units)`,
                        children: (
                            <BatchTable
                                title={(data) => (category !== "Damaged" ? tableTitle(data, category) : null)}
                                items={items}
                                onPrintLabels={(item, quantity) => {
                                    handlePrintLabels(item, quantity);
                                }}
                            />
                        ),
                    };
                }),
        [scannedItems, tableTitle, handlePrintLabels]
    );

    return (
        <Spin spinning={batchesLoading || shipmentPlansLoading || batchesRefetching || printerLoading}>
            <PrintHandler />
            <Divider />
            <Tabs centered defaultActiveKey={batchCategories[0]} items={globalIitems} />
            <ShipmentPlacementsDialog
                open={showPlacementOptions}
                onClose={() => setShowPlacementOptions(false)}
                onFinishBatch={async () => {
                    await finishBatchLocal();
                }}
            />
        </Spin>
    );
};

export default ShipmentPlanningDashboard;
