import {AppState, ImmerStateCreator, PackFirstReceivingSlice} from "../../../types/storeTypes";
import {
    confirmPackingOption,
    createInboundPlan,
    generatePackingOptions,
    listPackingGroupItems,
    listPackingOptions,
    waitForOperation,
} from "../../../services/SPApiService";
import {notification} from "antd";
import {BatchCategory, WarehouseProduct} from "../../../types/WarehouseTypes";
import {finishBatch, saveReceivedProducts} from "../../../services/WarehouseService";

export const createPackFirstReceivingSlice: ImmerStateCreator<AppState, PackFirstReceivingSlice> = (set, get) => ({
    packFirstReceivingData: {
        currentInboundPlanId: undefined,
        packingOptions: [],
        packingGroupItems: {},
        isSubmitting: false,
        isConfirming: false,
    },
    packFirstReceivingActions: {
        submitBatch: async (items: WarehouseProduct[]) => {
            const state = get();
            set((state) => {
                state.packFirstReceivingData.packingOptions = [];
                state.packFirstReceivingData.packingGroupItems = {};
            });

            if (!state.warehouseAddress) {
                throw new Error("Warehouse address is not set");
            }

            if (!state.warehouseContactInfo) {
                throw new Error("Warehouse contact is not set");
            }

            const shipmentName = `${new Date().toLocaleString("en-US")} WISC RAPIDS`;
            const devMode = false;

            const createInboundPlanRes = await createInboundPlan(
                shipmentName,
                items,
                state.warehouseAddress,
                state.warehouseContactInfo,
                devMode
            );
            const inboundPlanWaitRes = await waitForOperation(createInboundPlanRes.operationId, 2000, devMode);
            if (inboundPlanWaitRes.operationStatus !== "SUCCESS") {
                throw new Error("Failed to create inbound plan");
            }
            const inboundPlanId = createInboundPlanRes.inboundPlanId;
            // const inboundPlanId = "wf9f11d1c3-acda-4723-8356-cc9c2892f180";
            // const inboundPlanId = "wfdbe5fa9c-f1a6-43fe-8f0b-c67b5b7942a1";

            const operationId = await generatePackingOptions(inboundPlanId, devMode);
            const generatePackingOptionsWaitRes = await waitForOperation(operationId, 2000, devMode);
            if (generatePackingOptionsWaitRes.operationStatus !== "SUCCESS") {
                throw new Error("Failed to generate packing options");
            }

            const packingOptions = await listPackingOptions(inboundPlanId);

            // Fetch items for each packing group
            const packingGroupItems: {[key: string]: any[]} = {};
            for (const option of packingOptions) {
                for (const group of option.packingGroups) {
                    const items = await listPackingGroupItems(inboundPlanId, group, 20, devMode);
                    packingGroupItems[group] = items;
                }
            }

            set((state) => {
                state.packFirstReceivingData.packingOptions = packingOptions;
                state.packFirstReceivingData.packingGroupItems = packingGroupItems;
                state.packFirstReceivingData.currentInboundPlanId = inboundPlanId;
            });
        },
        fetchPackingOptions: async (inboundPlanId: string) => {
            try {
                set((state) => {
                    state.packFirstReceivingData.isSubmitting = true;
                });

                // Generate packing options
                const operationId = await generatePackingOptions(inboundPlanId);
                const waitRes = await waitForOperation(operationId);

                if (waitRes.operationStatus !== "SUCCESS") {
                    const problems = waitRes.operationProblems?.map((problem) => problem.message).join(" | ");
                    throw new Error(problems || "Failed to generate packing options");
                }

                // List packing options
                const packingOptions = await listPackingOptions(inboundPlanId);

                // Fetch items for each packing group
                const packingGroupItems: {[key: string]: any[]} = {};
                for (const option of packingOptions) {
                    for (const group of option.packingGroups) {
                        const items = await listPackingGroupItems(inboundPlanId, group);
                        packingGroupItems[group] = items;
                    }
                }

                set((state) => {
                    state.packFirstReceivingData.packingOptions = packingOptions;
                    state.packFirstReceivingData.packingGroupItems = packingGroupItems;
                    state.packFirstReceivingData.isSubmitting = false;
                });
            } catch (e: any) {
                notification.error({
                    message: "Error fetching packing options",
                    description: e.message,
                });
                set((state) => {
                    state.packFirstReceivingData.isSubmitting = false;
                });
            }
        },
        setCurrentInboundPlanId: (inboundPlanId) => {
            set((state) => {
                state.packFirstReceivingData.currentInboundPlanId = inboundPlanId;
            });
        },
        setPackingOptions: (options) => {
            set((state) => {
                state.packFirstReceivingData.packingOptions = options;
            });
        },
        setPackingGroupItems: (items) => {
            set((state) => {
                state.packFirstReceivingData.packingGroupItems = items;
            });
        },
        setIsSubmitting: (loading) => {
            set((state) => {
                state.packFirstReceivingData.isSubmitting = loading;
            });
        },
        setIsConfirming: (loading) => {
            set((state) => {
                state.packFirstReceivingData.isConfirming = loading;
            });
        },
        finishBatch: async (scannedItems: WarehouseProduct[], category: BatchCategory) => {
            const receivingData = get().packFirstReceivingData;

            // Merge all items from packing groups
            const mergedItems: {[key: string]: number} = {};
            Object.values(receivingData.packingGroupItems).forEach((groupItems) => {
                groupItems.forEach((item) => {
                    const {msku, quantity} = item;
                    if (mergedItems[msku]) {
                        mergedItems[msku] += quantity;
                    } else {
                        mergedItems[msku] = quantity;
                    }
                });
            });

            // Map scanned items with submitted quantities
            const mergedProducts: WarehouseProduct[] = scannedItems.map((product) => ({
                ...product,
                submittedQuantity: mergedItems[product.sku || ""] || 0,
            }));

            // Save received products
            await saveReceivedProducts(
                mergedProducts
                    .filter((product) => product.submittedQuantity && product.submittedQuantity > 0)
                    .map(({asin, sku, batchId, submittedQuantity}) => ({
                        sku,
                        asin,
                        batchId,
                        submittedQuantity,
                    }))
            );

            // Finish batch
            return await finishBatch(category);
        },
        confirmPackingOption: async (inboundPlanId, packingOptionId) => {
            try {
                set((state) => {
                    state.packFirstReceivingData.isConfirming = true;
                });

                const res = await confirmPackingOption(inboundPlanId, packingOptionId);
                const waitRes = await waitForOperation(res.operationId, 2000, false);
                if (waitRes.operationStatus !== "SUCCESS") {
                    throw new Error("Failed to confirm packing option");
                }
                // Update the packing options
                const packingOptions = await listPackingOptions(inboundPlanId);
                set((state) => {
                    state.packFirstReceivingData.packingOptions = packingOptions;
                });
            } catch (e: any) {
                notification.error({
                    message: "Error confirming packing option",
                    description: e.message,
                });
            }
            set((state) => {
                state.packFirstReceivingData.isConfirming = false;
            });
        },
    },
});
