import {OMSItem} from "../types/OmegaTypes";
import {getSellerSnap} from "./WholesaleService";

// const MM_TO_INCH_RATIO = 25.4;
const GRAM_TO_LB_RATIO = 454;
// const GRAM_TO_OUNCE_RATIO = 28.35;

export const getRoundedPrice = (proposed: number) => {
    if (proposed > 0) {
        const rounded = Math.ceil(proposed);

        if (rounded - 0.05 > proposed) {
            return rounded - 0.55;
        } else {
            return rounded - 0.05;
        }
    } else {
        return proposed;
    }
};

const setPricesFromKeepa = (newRow: OMSItem) => {
    // If current, avg_30, and avg_90 are all within 10% of each other, we set the min price 10% below the current one and the max price 10% above it
    const keepaData = newRow.KeepaData;

    if (keepaData?.stats) {
        let current = keepaData.stats.current[18] / 100;
        let avg30 = keepaData.stats.avg30[18] / 100;
        let avg90 = keepaData.stats.avg90[18] / 100;

        // if no BB has been recorded in the last 90 days we want to use new prices instead
        if (current <= 0 && avg30 <= 0 && avg90 <= 0) {
            current = keepaData.stats.current[1] / 100;
            avg30 = keepaData.stats.avg30[1] / 100;
            avg90 = keepaData.stats.avg90[1] / 100;
        }

        // check if current, avg_30, and avg_90 are all within 10% of each other
        if (
            current > 0 &&
            avg30 > 0 &&
            avg90 > 0 &&
            current > avg30 * 0.9 &&
            current < avg30 * 1.1 &&
            current > avg90 * 0.9 &&
            current < avg90 * 1.1
        ) {
            newRow["Min Price"] = current * 0.9;
            newRow["Max Price/List"] = current * 1.1;
        } else {
            // Otherwise, we set max price as max(current, avg_30, avg_90)
            // If MVSP < min(current, avg_30, avg_90), we set min as the MVSP, Otherwise, we set min price as 10% below avg_90
            const mvsp = newRow.MVSP || 0;
            newRow["Min Price"] = mvsp < Math.min(...[current, avg30, avg90].filter((el) => el > 0), 0) ? mvsp : avg90 * 0.9;
            newRow["Max Price/List"] = Math.max(current, avg30, avg90) > 0 ? Math.max(current, avg30, avg90) : undefined;
        }
    } else {
        newRow["Min Price"] = newRow.MVSP || undefined;
        newRow["Max Price/List"] = newRow.MVSP ? newRow.MSVP * 1.5 || undefined : undefined;
    }

    // After getting these min and max prices, we check if max-min is > $1, ensuring it is. If current > $20, we also make sure max-min is > $2
    if (newRow["Min Price"] && newRow["Max Price/List"]) {
        const minPrice = newRow["Min Price"];
        const maxPrice = newRow["Max Price/List"];

        if (maxPrice - minPrice < 1) {
            newRow["Max Price/List"] = minPrice + 1;
        }

        if (maxPrice - minPrice < 2 && minPrice > 20) {
            newRow["Max Price/List"] = minPrice + 2;
        }
    }

    // Then, all values are rounded to the nearest $.45 or $.95
    if (newRow["Min Price"]) {
        newRow["Min Price"] = getRoundedPrice(newRow["Min Price"]);
    }

    if (newRow["Max Price/List"]) {
        newRow["Max Price/List"] = getRoundedPrice(newRow["Max Price/List"]);
    }

    return newRow;
};

export const grabSellerSnap = async (products: OMSItem[]): Promise<OMSItem[]> => {
    const sellerSnapData = (
        await getSellerSnap(
            "true",
            products.map((prod) => prod.ASIN!)
        )
    ).reduce((map: {[key: string]: any}, obj) => ((map[obj.asin] = obj), map), {});

    let productsCopy = products.map((row) => {
        const newRow = {...row};

        const asin = newRow["ASIN"] || "";
        newRow.PackageWeight =
            newRow.PackageWeight ||
            ((newRow?.KeepaData?.packageWeight || 0) > 0 ? (newRow?.KeepaData?.packageWeight || 0) / GRAM_TO_LB_RATIO : 0);
        newRow.PackageWeight = parseFloat(newRow.PackageWeight);
        newRow.ShipFee = 1 + (newRow.PackageWeight || 2) * 0.5;

        newRow.FulfillmentFee = parseFloat(
            sellerSnapData[asin]?.fulfillment_cost ??
                (newRow?.KeepaData?.fbaFees?.pickAndPackFee > 0 ? newRow?.KeepaData?.fbaFees?.pickAndPackFee / 100 : 0)
        );

        // newRow.FulfillmentFee = parseFloat(
        //     newRow?.KeepaData?.fbaFees?.pickAndPackFee > 0
        //         ? newRow?.KeepaData?.fbaFees?.pickAndPackFee / 100
        //         : sellerSnapData[asin]?.fulfillment_cost ?? 0
        // );
        newRow.MVSP = ((newRow.Cost || 0) * 1.35 + newRow.ShipFee + newRow.FulfillmentFee) / 0.85;

        if (!newRow["Min Price"]) {
            if (sellerSnapData[asin]?.min_price) {
                newRow["Min Price"] = parseFloat(sellerSnapData[asin]?.min_price || 0);
            } else {
                newRow["Min Price"] = undefined;
            }
        }

        newRow["Min Price"] = newRow["Min Price"] || sellerSnapData[asin]?.min_price || 0;
        newRow["Max Price/List"] = newRow["Max Price/List"] || sellerSnapData[asin]?.max_price || 0;

        if (newRow["Min Price"] && newRow["Max Price/List"]) {
            if (typeof newRow["Min Price"] === "string") {
                newRow["Min Price"] = parseFloat(newRow["Min Price"]);
            }

            if (typeof newRow["Max Price/List"] === "string") {
                newRow["Max Price/List"] = parseFloat(newRow["Max Price/List"]);
            }
        } else {
            console.log("grabbing from keepa instead lule");
            setPricesFromKeepa(newRow);
        }

        if (newRow?.KeepaData?.stats) {
            newRow["Sell Price"] = 0;
            const currentBuyBoxPrice = Math.max(newRow.KeepaData.stats.current[18], 0) / 100;
            if (newRow.KeepaData.stats.avg30[18] > 0 && currentBuyBoxPrice > 0) {
                newRow["Sell Price"] = (currentBuyBoxPrice + newRow.KeepaData.stats.avg30[18] / 100) / 2;
            } else if (newRow.KeepaData.stats.avg30[18] > 0) {
                newRow["Sell Price"] = newRow.KeepaData.stats.avg30[18] / 100;
            } else if (newRow.KeepaData.stats.avg90[18] > 0) {
                newRow["Sell Price"] = newRow.KeepaData.stats.avg90[18] / 100;
            } else if (currentBuyBoxPrice > 0) {
                newRow["Sell Price"] = currentBuyBoxPrice;
            } else {
                newRow["Sell Price"] = -1;
            }

            if (currentBuyBoxPrice && newRow["Sell Price"] > currentBuyBoxPrice) {
                newRow["Sell Price"] = currentBuyBoxPrice;
            }
        } else {
            newRow["Sell Price"] = -1;
        }

        newRow["Seasonal Tag"] =
            newRow["Seasonal Tag"] || sellerSnapData[asin]?.tags?.find((tag: string) => ["EG", "Summer", "Winter", "Cool"].includes(tag));

        if (newRow.MAP && newRow.MAP > 0 && !newRow["Seasonal Tag"]?.includes("MAP")) {
            newRow["Seasonal Tag"] = (newRow["Seasonal Tag"]?.length ?? 0) > 0 ? `${newRow["Seasonal Tag"]};MAP` : "MAP";
        }

        newRow["S3_Pushed?"] = newRow["S3_Pushed?"] || "NO";
        newRow.DesiredROI = newRow.DesiredROI || 30;
        newRow.DesiredROI = parseFloat(newRow.DesiredROI);
        newRow.SellerSnapData = sellerSnapData[asin];

        return newRow;
    });

    return productsCopy;
};
