import React, {useEffect, useState} from "react";
import {Modal, Table, Typography, Button, Space, Input, notification} from "antd";
import {Invoice, InvoiceSampleItem} from "../../../types/OmegaTypes";
import {ArrowRightOutlined} from "@ant-design/icons";
import {ColumnsType} from "antd/lib/table";
import {saveInvoiceValidation} from "../../../services/WholesaleService";

const {Text} = Typography;

interface ValidationInputProps {
    value: string;
    enteredValue: string | undefined;
    onChange: (value: string) => void;
    width?: number;
}

const ValidationInput = ({value, enteredValue, onChange, width}: ValidationInputProps) => {
    let color: string | undefined = undefined;
    if (value === enteredValue) {
        color = "#70F085FF";
    } else if (value !== enteredValue && enteredValue) {
        color = "#FF0000";
    }
    return (
        <Space direction="horizontal" style={{width: "100%"}}>
            <Text>{value || "N/A"}</Text>
            <Button icon={<ArrowRightOutlined style={{color}} />} onClick={() => onChange(value)} disabled={!value} />
            <Input style={{width: width}} value={enteredValue} onChange={(e) => onChange(e.target.value)} disabled={!value} />
        </Space>
    );
};

interface InvoiceValidationDialogProps {
    open: boolean;
    invoiceData?: {
        invoice: Invoice;
        callId: string;
    };
    onConfirm: () => void;
    onCancel: () => void;
}

type EnteredItem = Partial<InvoiceSampleItem>;

export const InvoiceValidationDialog: React.FC<InvoiceValidationDialogProps> = ({open, invoiceData, onConfirm, onCancel}) => {
    const [sampleItems, setSampleItems] = useState<InvoiceSampleItem[]>([]);
    const [enteredItems, setEnteredItems] = useState<{[key: string]: EnteredItem}>({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (invoiceData) {
            const invoiceItems = [...invoiceData.invoice.items];
            // Shuffle the items
            invoiceItems.sort(() => Math.random() - 0.5);
            const sampleItems: InvoiceSampleItem[] = [];
            invoiceItems.forEach((item) => {
                if (item.sizes) {
                    Object.entries(item.sizes).forEach(([size, quantity]) => {
                        sampleItems.push({item_number: item.item_number, color: item.color, size, quantity});
                    });
                } else {
                    sampleItems.push({item_number: item.item_number, color: item.color, quantity: item.quantity});
                }
            });
            setSampleItems(sampleItems.slice(0, 3));
            const enteredItems: {[key: string]: EnteredItem} = {};
            sampleItems.forEach((item) => {
                enteredItems[`${item.item_number}-${item.color}-${item.size}`] = {
                    item_number: undefined,
                    color: undefined,
                    size: undefined,
                    quantity: undefined,
                };
            });
            setEnteredItems(enteredItems);
        }
    }, [invoiceData]);

    const columns: ColumnsType<InvoiceSampleItem> = [
        {
            title: "Item Number",
            dataIndex: "item_number",
            key: "item_number",
            render: (value, record) => {
                const key = `${value}-${record.color}-${record.size}`;
                return (
                    <ValidationInput
                        value={value}
                        enteredValue={enteredItems[key].item_number}
                        onChange={(value) => {
                            setEnteredItems((prev) => ({
                                ...prev,
                                [key]: {...prev[key], item_number: value},
                            }));
                        }}
                        width={200}
                    />
                );
            },
        },
        {
            title: "Color",
            dataIndex: "color",
            key: "color",
            render: (value, record) => {
                const key = `${record.item_number}-${value}-${record.size}`;
                return (
                    <ValidationInput
                        value={value}
                        enteredValue={enteredItems[key].color}
                        onChange={(value) => {
                            setEnteredItems((prev) => ({
                                ...prev,
                                [key]: {...prev[key], color: value},
                            }));
                        }}
                        width={200}
                    />
                );
            },
        },
        {
            title: "Size",
            dataIndex: "size",
            key: "size",
            render: (value, record) => {
                const key = `${record.item_number}-${record.color}-${value}`;
                return (
                    <ValidationInput
                        value={value}
                        enteredValue={enteredItems[key].size}
                        onChange={(value) => {
                            setEnteredItems((prev) => ({...prev, [key]: {...prev[key], size: value}}));
                        }}
                        width={60}
                    />
                );
            },
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            render: (value, record) => {
                const key = `${record.item_number}-${record.color}-${record.size}`;
                return (
                    <ValidationInput
                        value={value?.toString()}
                        enteredValue={enteredItems[key].quantity?.toString()}
                        onChange={(value) => {
                            setEnteredItems((prev) => ({
                                ...prev,
                                [key]: {...prev[key], quantity: value ? parseInt(value, 10) : undefined},
                            }));
                        }}
                        width={50}
                    />
                );
            },
        },
    ];

    const handleConfirm = async () => {
        try {
            setIsLoading(true);
            // Check if all items are entered correctly
            const allCorrect = sampleItems.every((item) => {
                const key = `${item.item_number}-${item.color}-${item.size}`;
                const enteredItem = enteredItems[key];
                return (
                    enteredItem.item_number !== undefined &&
                    enteredItem.quantity !== undefined &&
                    !!enteredItem.color === !!item.color &&
                    !!enteredItem.size === !!item.size
                );
            });
            if (allCorrect && invoiceData) {
                await saveInvoiceValidation(invoiceData.callId, {
                    po_number: invoiceData.invoice.po_number,
                    invoice_number: invoiceData.invoice.invoice_number,
                    items: sampleItems.map((item) => {
                        const enteredItem = enteredItems[`${item.item_number}-${item.color}-${item.size}`];
                        return {
                            item_number: enteredItem.item_number || "",
                            color: enteredItem.color,
                            size: enteredItem.size,
                            quantity: enteredItem.quantity ?? -1,
                        };
                    }),
                });
                onConfirm();
            } else {
                throw new Error("Please fill in all fields correctly");
            }
        } catch (error: any) {
            notification.error({
                message: "Error",
                description: error.message,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            title="Validate Invoice Data"
            open={open}
            onCancel={onCancel}
            width={1200}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Skip
                </Button>,
                <Button key="confirm" type="primary" onClick={handleConfirm} loading={isLoading}>
                    Confirm
                </Button>,
            ]}
        >
            <Space direction="vertical" style={{width: "100%"}} size="large">
                <Typography.Text>
                    Please verify each item against your invoice. Click the arrow button to auto-fill if correct, or enter the correct value
                    manually if incorrect.
                </Typography.Text>
                <Table dataSource={sampleItems} columns={columns} />
            </Space>
        </Modal>
    );
};
