import {useQuery, useQueryClient} from "@tanstack/react-query";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {getReceivedBatches, savePackingOption} from "../../../services/WarehouseService";
import {BatchCategory, InboundPackingOption, InboundShipmentItemV2, WarehouseProduct, batchCategories} from "../../../types/WarehouseTypes";
import {parseBatches} from "../common/helpers";
import {Button, Divider, Space, Spin, Tabs, TabsProps, notification} from "antd";
import BatchTable from "../common/BatchTable";
import {useStore} from "../../../store/useStore";
import PrintHandler from "../common/PrintHandler";
import {useAuth} from "../../../contexts/AuthContext";
import {useShallow} from "zustand/react/shallow";
import PackingOptionsDialog from "./PackingOptionsDialog";
import {PackingOption} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model-v20240320";

const ShipmentPlanningDashboard: React.FC = () => {
    const {currentUser} = useAuth();
    const {receivingData, receivingActions, printerLoading, handlePrintLabels} = useStore(
        useShallow((state) => ({
            receivingData: state.packFirstReceivingData,
            receivingActions: state.packFirstReceivingActions,
            printerLoading: state.printerLoading,
            handlePrintLabels: state.handlePrint,
        }))
    );
    const [scannedItems, setScannedItems] = useState<{
        [key in BatchCategory]?: WarehouseProduct[];
    }>({HazMat: []});
    const [currentCategory, setCurrentCategory] = useState<BatchCategory>(batchCategories[0]);
    const queryClient = useQueryClient();
    const [showPackingOptions, setShowPackingOptions] = useState(false);

    const {
        data: batches,
        isLoading: batchesLoading,
        isRefetching: batchesRefetching,
    } = useQuery({
        queryKey: ["shipment_planning_batches", currentUser?.uid],
        queryFn: async () => {
            const data = await getReceivedBatches();
            return data;
        },
    });

    useEffect(() => {
        if (batches) {
            const items = parseBatches(batches);
            setScannedItems(items);
        }
    }, [batches]);

    const submitBatch = useCallback(
        async (items: WarehouseProduct[], category: BatchCategory) => {
            setCurrentCategory(category);
            receivingActions.setIsSubmitting(true);
            try {
                await receivingActions.submitBatch(items);
                setShowPackingOptions(true);
            } catch (error: any) {
                notification.error({
                    message: "Error",
                    description: error.message,
                });
            }
            receivingActions.setIsSubmitting(false);
        },
        [receivingActions]
    );

    const finishBatchLocal = async (packingOption: PackingOption) => {
        const inboundPlanId = receivingData.currentInboundPlanId;
        if (!inboundPlanId) {
            throw new Error("No inbound plan ID set");
        }
        let batch = batches?.find((batch) => batch.category === currentCategory);
        if (!batch) {
            throw new Error("No batch found");
        }
        const inboundPackingOption: InboundPackingOption = {
            ...packingOption,
            inboundPlanId,
            processingStatus: "WORKING",
            batchId: batch._id,
        };

        const items: InboundShipmentItemV2[] = [];
        for (const group of inboundPackingOption.packingGroups) {
            items.push(...receivingData.packingGroupItems[group].map((item) => ({...item, packingGroupId: group, inboundPlanId})));
        }

        await savePackingOption(inboundPackingOption, items);

        batch = await receivingActions.finishBatch(scannedItems[currentCategory] || [], currentCategory);
        if (batch) {
            setScannedItems((prev) => ({...prev, [batch?.category || ""]: batch?.items || []}));
        }
        notification.success({
            message: "Success",
            description: "Successfully finished batch",
        });
        queryClient.refetchQueries({queryKey: ["shipment_planning_batches"]});
        queryClient.refetchQueries({queryKey: ["batches", currentUser?.uid]});
        setShowPackingOptions(false);
    };

    const tableTitle = useCallback(
        (data: readonly WarehouseProduct[], category: BatchCategory) => (
            <Space direction="horizontal" style={{width: "100%", justifyContent: "start"}} split={<Divider type="vertical" />}>
                <Button
                    onClick={() => {
                        submitBatch(
                            data.map((item) => ({...item})),
                            category
                        );
                    }}
                >
                    Submit
                </Button>
            </Space>
        ),
        [submitBatch]
    );

    const globalIitems: TabsProps["items"] = useMemo(
        () =>
            batchCategories
                .filter((category) => (scannedItems[category] || []).length > 0)
                .map((category) => {
                    const items = scannedItems[category] || [];
                    const units = items.reduce((acc, item) => acc + item.quantity, 0);
                    return {
                        key: category,
                        label: `${category} (${items.length} SKUs, ${units} units)`,
                        children: (
                            <BatchTable
                                title={(data) => (category !== "Damaged" ? tableTitle(data, category) : null)}
                                items={items}
                                onPrintLabels={(item, quantity) => {
                                    handlePrintLabels(item, quantity);
                                }}
                            />
                        ),
                    };
                }),
        [scannedItems, tableTitle, handlePrintLabels]
    );

    return (
        <Spin spinning={batchesLoading || receivingData.isSubmitting || batchesRefetching || printerLoading}>
            <PrintHandler />
            <Divider />
            <Tabs centered defaultActiveKey={batchCategories[0]} items={globalIitems} />
            <PackingOptionsDialog open={showPackingOptions} onClose={() => setShowPackingOptions(false)} onFinishBatch={finishBatchLocal} />
        </Spin>
    );
};

export default ShipmentPlanningDashboard;
