import {InventoryItem, SellerSnapItem, WholesaleItem} from "../types/WholesaleItem";
import {
    APInvoiceTrackerData,
    BuySheetItem,
    InventoryHealthItem,
    InvoiceData,
    LedgerData,
    OMSItem,
    OMSTrackerItem,
    SellerSnapExportData,
    SellerSnapUpdateItem,
    Supplier,
    FreightLogRow,
    FreightLogItemizedEntry,
    FreightLogItemizedRow,
    CurrentShipmentLogItem,
    SkuAsinItem,
    InquiryItem,
    Invoice,
    InvoiceIdMatch,
    InvoiceSampleItem,
} from "../types/OmegaTypes";
import {ApiKey, KeepaApiKey} from "./constants";
import firebase from "firebase";
import {BASE_URL_V2, getIdToken, handleError, fileToBase64} from "./common";
import {parseOMSColumns} from "../components/utilities/OMSCalculations";
import {GetSheet} from "./OmegaService";
import {BrandDashboardItem} from "../types/Brand";

const DEVELOPMENT_RESPONSE = false;
const DEVELOPMENT_CALL = process.env.NODE_ENV === "development";
const PS_DOMAIN = process.env.REACT_APP_PS_DOMAIN;
export const BASE_URL = PS_DOMAIN ? PS_DOMAIN : DEVELOPMENT_CALL ? "http://localhost:3001" : "https://api.projectsuite.io";
export const SUPPLIER_PREFIX = "Supplier_";
// const BASE_URL = 'https://api.projectsuite.io';
// const BASE_URL = 'http://localhost:3001';
const MAX_UID = "luOjCSlnssfwomE0Lic6gHzmDcw1";

export const compareSupplierValues = (a: WholesaleItem, b: WholesaleItem) => {
    const aSupplierData = Object.fromEntries(Object.entries(a).filter(([key, val]) => key.includes(SUPPLIER_PREFIX)));
    const bSupplierData = Object.fromEntries(Object.entries(b).filter(([key, val]) => key.includes(SUPPLIER_PREFIX)));

    return JSON.stringify(aSupplierData) === JSON.stringify(bSupplierData);
};

export function getProducts(collection: string): Promise<WholesaleItem[]> {
    return fetch(`${BASE_URL}/wholesale?collection=${encodeURIComponent(collection)}&key=${ApiKey}`)
        .then((res) => res.json())
        .then((res) =>
            res.result.map((prod: WholesaleItem) => ({
                ...prod,
                sourceCollection: collection,
            }))
        );
}

export function getCollections(saved: boolean): Promise<string[]> {
    return fetch(`${BASE_URL}/wholesale/collections?key=${ApiKey}${saved ? `&saved=true` : ""}`)
        .then((res) => res.json())
        .then((data) =>
            data.result.map((col: any) => col.name).filter((col: string) => !col.includes("2022") && !col.match(/\d\d.\d\d.22/g))
        );
}

export function getCollectionsForUser(saved: boolean, user: firebase.User): Promise<string[]> {
    return user
        .getIdToken()
        .then((token: string) => fetch(`${BASE_URL}/wholesale/collections?firebase_token=${token}${saved ? `&saved=true` : ""}`))
        .then((res: any) => res.json())
        .then((data: any) => data.result.map((col: any) => col.name));
}

export function getInventory(token: string): Promise<InventoryItem[]> {
    return fetch(`${BASE_URL}/repricer?firebase_token=${token}&collection=inventory&db=luOjCSlnssfwomE0Lic6gHzmDcw1`)
        .then((res) => res.json())
        .then((data) =>
            DEVELOPMENT_RESPONSE
                ? []
                : data.result.map((col: any) => ({
                      ...col,
                      TotalQuantity: parseInt(col.TotalQuantity),
                  }))
        );
}

export function getShippedItems(token: string, days: number, asins: string[] = []): Promise<any> {
    return fetch(`${BASE_URL}/shipments/getShippedItemsWS?firebase_token=${token}&days=${days}`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({asins}),
    })
        .then((res) => res.json())
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

export function getShipmentData(token: string, days: number): Promise<any> {
    return fetch(`${BASE_URL}/shipments/getShipmentData?firebase_token=${token}&lastDays=${days}`).then((res) => res.json());
}

export function getScheduleData(token: string): Promise<any> {
    return GetSheet({
        token: token,
        spreadsheetId: "1PqZMpaQee5HXOIb6ELKHd1mt4EMivchQ0y_pWmRLrMY",
        range: "A:B",
    });
}

export function getExperimentalInventory(token: string, asins: string[] = []): Promise<InventoryItem[]> {
    return fetch(`${BASE_URL}/managedb/getSome?firebase_token=${token}&collection=experimentalinventory&db=luOjCSlnssfwomE0Lic6gHzmDcw1`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
            searchValue: asins,
            searchKey: "asin",
            searchOp: "$in",
        }),
    })
        .then((res) => res.json())
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

export function getDashboardItems(token: string, asins: string[] = []): Promise<BrandDashboardItem[]> {
    return fetch(`${BASE_URL}/managedb/getSome?firebase_token=${token}&collection=supplier_items&db=prelisting`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
            searchValue: asins,
            searchKey: "ASIN",
            searchOp: "$in",
        }),
    })
        .then((res) => res.json())
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

export async function getOMS(token: string, pos?: string, projectionType?: "packagedims" | "stagethree" | "warehouse"): Promise<OMSItem[]> {
    let result: OMSItem[] = [];
    if (!pos) {
        result = await fetch(`${BASE_URL}/prelisting/getAll?firebase_token=${token}`)
            .then((res) => res.json())
            .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
    } else {
        let url = `${BASE_URL}/prelisting/getPOs?firebase_token=${token}`;
        if (projectionType) {
            url += `&projectionType=${projectionType}`;
        }
        result = await fetch(url, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({pos}),
        })
            .then((res) => res.json())
            .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
    }
    return parseOMSColumns(result);
}

export async function getOMSPOs(token: string): Promise<OMSItem[]> {
    let result: OMSItem[] = [];
    result = await fetch(`${BASE_URL}/prelisting/getAll?projection=dashboard&firebase_token=${token}`)
        .then((res) => res.json())
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
    return parseOMSColumns(result);
}

export async function getRecentOMS(token: string, asins: string[], days: number = 90): Promise<OMSItem[]> {
    const result = await fetch(`${BASE_URL}/prelisting/getRecent?firebase_token=${token}&days=${days}`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({asins}),
    })
        .then((res) => res.json())
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
    return parseOMSColumns(result);
}

export async function getOMSByIds(token: string, ids: string[]): Promise<OMSItem[]> {
    let result: OMSItem[] = await fetch(`${BASE_URL}/prelisting/getByIds?firebase_token=${token}`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({ids}),
    })
        .then(handleError)
        .then((data: any) => (DEVELOPMENT_RESPONSE ? [] : data.result));

    return parseOMSColumns(result);
}

export async function updateOMS(
    token: string,
    items: Partial<OMSItem>[],
    includeKeepa: boolean,
    stage: string = "default"
): Promise<Partial<OMSItem>[]> {
    await fetch(`${BASE_URL}/prelisting/add?firebase_token=${token}&include_keepa=${includeKeepa}&stage=${stage}`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({listings: items}),
    }).then(handleError);

    return items;
}

export async function deleteOMSByIds(token: string, values: string[], type: string = "ID") {
    await fetch(`${BASE_URL}/prelisting/removeByIds?firebase_token=${token}`, {
        method: "DELETE",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
            type,
            value: values,
        }),
    }).then(handleError);
}

export async function deleteOMS(token: string, values: {Supplier_PO: string; Supplier_SO?: string}[]) {
    await fetch(`${BASE_URL}/prelisting/remove?firebase_token=${token}`, {
        method: "DELETE",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
            values,
        }),
    }).then(handleError);
}

export function getOMSTracker(token: string, archived: boolean = true): Promise<OMSTrackerItem[]> {
    return fetch(`${BASE_URL}/prelisting/tracker/getAll?firebase_token=${token}&archived=${archived}`)
        .then((res) => res.json())
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

export function getOMSTrackerEntry(po: string, so: string): Promise<OMSTrackerItem[]> {
    return getIdToken()
        .then((token) => fetch(`${BASE_URL}/prelisting/tracker/getOne?firebase_token=${token}&po=${po}&so=${so}`))
        .then((res) => res.json())
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

export function getShipLater(token: string): Promise<any[]> {
    return fetch(`https://api.projectsuite.io/managedb/getAll?firebase_token=${token}&db=prelisting&collection=shiplater`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((res) => res.json())
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

export function getInventoryHealth(token: string): Promise<InventoryHealthItem[]> {
    return fetch(`https://api.projectsuite.io/managedb/getAll?firebase_token=${token}&db=${MAX_UID}&collection=inventory_planning`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((res) => res.json())
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

export function getSkuAsin(token: string): Promise<SkuAsinItem[]> {
    return fetch(`https://api.projectsuite.io/managedb/getAll?firebase_token=${token}&db=${MAX_UID}&collection=alllistings`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((res) => res.json())
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

export function getSellerSnap(monthly: string = "true", asins: string[] = []): Promise<SellerSnapItem[]> {
    return fetch(`https://api.repricer.projectsuite.io/requestSellerSnap?${monthly ? `monthly=${monthly}` : ""}&fresh=true`, {
        method: "POST",
        body: JSON.stringify({asins}),
        headers: {
            "Authorization": "maximumrepricer",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
}

export function getSellerSnapVeryFresh(monthly: string = "true"): Promise<SellerSnapItem[]> {
    return fetch(`https://api.repricer.projectsuite.io/sellersnap?${monthly ? `monthly=${monthly}` : ""}&veryfresh=true`, {
        method: "GET",
        headers: {
            Authorization: "maximumrepricer",
        },
    }).then((res) => res.json());
}

export function pushSellerSnapUpdate(data: SellerSnapUpdateItem[]) {
    return fetch(`https://api.repricer.projectsuite.io/sellersnap`, {
        method: "POST",
        body: JSON.stringify({updateData: data}),
        headers: {
            "Content-Type": "application/json",
            "authorization": "maximumrepricer",
        },
    }).then(handleError);
}

export function importProductsForUser(collection: string, formData: FormData, token: string) {
    return fetch(`${BASE_URL}/wholesale?collection=${collection}&firebase_token=${token}`, {
        method: "POST",
        body: formData,
    });
}

export function saveProduct(product: WholesaleItem) {
    const body = {
        product: product,
    };

    return fetch(`${BASE_URL}/wholesale/save?key=${ApiKey}`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body),
    });
}

export function updateProduct(products: WholesaleItem[]) {
    const body = {
        products: products,
    };

    return fetch(`${BASE_URL}/wholesale/update?key=${ApiKey}`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body),
    });
}

export function deleteCollection(collection: string, token: string) {
    return fetch(`${BASE_URL}/wholesale?collection=${collection}&firebase_token=${token}`, {
        method: "DELETE",
    }).then((res) => res.json());
}

export function getReviewData(asin: string, token: string) {
    return fetch(`${BASE_URL}/wholesale/reviews?asin=${asin}&firebase_token=${token}`, {
        method: "GET",
    }).then((res) => res.json());
}

export function getFeeData(token: string, requestedItems: {asin: string; price: number}[]) {
    return fetch(`${BASE_URL}/SpApi/fees/getFees?firebase_token=${token}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestedItems),
    }).then((res) => res.json());
}

export function getRestrictions(token: string, requestedItems: {asin: string}[]) {
    return fetch(`${BASE_URL}/SpApi/restrictions/getRestrictions?firebase_token=${token}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestedItems),
    }).then((res) => res.json());
}

export function getHazmat(token: string, requestedItems: {asin: string}[]) {
    return fetch(`${BASE_URL}/SpApi/restrictions/getHazmat?firebase_token=${token}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestedItems),
    }).then((res) => res.json());
}

export function getAggregatedWholesaleData(token: string, asins: string[], days_num = 30) {
    return fetch(
        `https://v2.api.repricer.projectsuite.io/stats/wholesale_app_arbitrary_timeframe_data?token=${token}&days_num=${days_num}`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                identifiers: asins,
                options: {
                    bb_stats: false,
                    order_stats: true,
                },
            }),
        }
    ).then((res) => res.json());
}

export function getAvailableSuppliers(token: string) {
    return fetch(`${BASE_URL}/oms/getSuppliers?firebase_token=${token}`, {
        method: "GET",
    })
        .then(handleError)
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

export function updateSuppliers(token: string, suppliers: Supplier[]): Promise<Supplier[]> {
    const body = {
        suppliers,
    };

    return fetch(`${BASE_URL}/oms/updateSuppliers?firebase_token=${token}`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body),
    })
        .then(handleError)
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

function getOMSDocument(token: string, collection: string, sortKey: string = "Timestamp"): Promise<any[]> {
    return fetch(`${BASE_URL}/oms/getDocuments?firebase_token=${token}&collection=${collection}&sortKey=${sortKey}`, {
        method: "GET",
    })
        .then(handleError)
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

function updateOMSDocument(token: string, collection: string, items: any[]): Promise<any[]> {
    const body = {
        documents: items,
    };

    return fetch(`${BASE_URL}/oms/updateDocuments?firebase_token=${token}&collection=${collection}`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body),
    })
        .then(handleError)
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

function deleteOMSDocument(token: string, collection: string, ids: string[]): Promise<void> {
    const body = {
        ids,
    };

    return fetch(`${BASE_URL}/oms/deleteDocuments?firebase_token=${token}&collection=${collection}`, {
        method: "DELETE",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body),
    }).then(handleError);
}

export function deleteSuppliers(token: string, ids: string[]) {
    return deleteOMSDocument(token, "suppliers", ids);
}

export function generateBuysheet(token: string, posString: string): Promise<BuySheetItem[]> {
    return fetch(`${BASE_URL}/prelisting/generateBuysheet?firebase_token=${token}&pos=${posString}`, {
        method: "GET",
    })
        .then(handleError)
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

export function deleteInvoices(token: string, ids: string[]) {
    return deleteOMSDocument(token, "invoices", ids);
}

export function getInvoices(token: string): Promise<InvoiceData[]> {
    return getOMSDocument(token, "invoices");
}

export function updateInvoices(token: string, invoices: InvoiceData[]): Promise<InvoiceData[]> {
    return updateOMSDocument(token, "invoices", invoices);
}

export function deleteLedgerData(token: string, ids: string[]) {
    return deleteOMSDocument(token, "ledger", ids);
}

export function getLedgerData(token: string): Promise<LedgerData[]> {
    return fetch(`${BASE_URL}/oms/getLedgerData?firebase_token=${token}`, {
        method: "GET",
    })
        .then(handleError)
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

export function updateLedgerData(token: string, ledgerData: LedgerData[]): Promise<LedgerData[]> {
    return updateOMSDocument(token, "ledger", ledgerData);
}

export function getSellerSnapExport(token: string): Promise<SellerSnapExportData[]> {
    return fetch(`${BASE_URL}/prelisting/generateSellerSnap?firebase_token=${token}`, {
        method: "GET",
    })
        .then(handleError)
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

export function generateTrackerData(token: string, return_data: boolean = true): Promise<OMSTrackerItem[]> {
    return fetch(`${BASE_URL}/prelisting/generateTrackerData?firebase_token=${token}&return_data=${return_data}&save=true`, {
        method: "GET",
    })
        .then(handleError)
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

export function pushTrackerData(token: string, items: Partial<OMSTrackerItem>[], refreshInLastPush: boolean = true): Promise<void> {
    return fetch(`${BASE_URL}/prelisting/tracker/add?firebase_token=${token}&refreshInLastPush=${refreshInLastPush}`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({listings: items}),
    }).then(handleError);
}

export async function updateOMSPrelisted(token: string, items: OMSTrackerItem[]) {
    await fetch(`${BASE_URL}/prelisting/tracker/updateProducts?firebase_token=${token}`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
            items,
        }),
    }).then(handleError);
}

export function deleteAPTrackerData(token: string, ids: string[]) {
    return deleteOMSDocument(token, "aptracker", ids);
}

export function getAPTrackerData(token: string): Promise<APInvoiceTrackerData[]> {
    return fetch(`${BASE_URL}/oms/getAPTrackerData?firebase_token=${token}`, {
        method: "GET",
    })
        .then(handleError)
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

export function updateAPTrackerData(token: string, invoices: APInvoiceTrackerData[]): Promise<APInvoiceTrackerData[]> {
    return updateOMSDocument(token, "aptracker", invoices);
}

export function getFreightLog(token: string): Promise<FreightLogRow[]> {
    return fetch(`${BASE_URL}/oms/getFreightLogData?firebase_token=${token}`, {
        method: "GET",
    })
        .then(handleError)
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

export function updateFreightLog(token: string, freightRows: FreightLogRow[]): Promise<FreightLogRow[]> {
    return updateOMSDocument(token, "freight_log", freightRows);
}

export function deleteFreightLog(token: string, ids: string[]) {
    return deleteOMSDocument(token, "freight_log", ids);
}

export function getPOTrackerArchive(token: string): Promise<OMSTrackerItem[]> {
    return getOMSDocument(token, "potracker_archive", "Ship_Requested");
}

export function updatePOTrackerArchive(token: string, items: OMSTrackerItem[]): Promise<OMSTrackerItem[]> {
    return updateOMSDocument(token, "potracker_archive", items);
}

export function deletePOTrackerArchive(token: string, ids: string[]) {
    return deleteOMSDocument(token, "potracker_archive", ids);
}

export function getItemizedFreightLogEntry(token: string, posString: string): Promise<FreightLogItemizedEntry[]> {
    return fetch(`${BASE_URL}/oms/getItemizedFreightLogEntry?firebase_token=${token}&pos=${posString}`, {
        method: "GET",
    })
        .then(handleError)
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

export function getFreightLogItemized(token: string): Promise<FreightLogItemizedRow[]> {
    return getOMSDocument(token, "freight_log_itemized");
}

export function updateFreightLogItemized(token: string, items: FreightLogItemizedRow[]): Promise<FreightLogItemizedRow[]> {
    return updateOMSDocument(token, "freight_log_itemized", items);
}

export function deleteFreightLogItemized(token: string, ids: string[]) {
    return deleteOMSDocument(token, "freight_log_itemized", ids);
}

export function generatePackageDimsReport(token: string, posString: string): Promise<Blob | null> {
    return fetch(`${BASE_URL}/prelisting/generatePackageDimsReport?firebase_token=${token}&pos=${encodeURIComponent(posString)}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
    })
        .then(async (response) => {
            if (!response.ok) {
                const res = await response.json();
                throw Error(res.error);
            }
            return response.blob();
        })
        .then((data) => (DEVELOPMENT_RESPONSE ? null : data));
}

export function getCurrentShipmentLog(token: string): Promise<CurrentShipmentLogItem[]> {
    return getOMSDocument(token, "current_shipment_log");
}

export function getInquiries(token: string): Promise<InquiryItem[]> {
    return getOMSDocument(token, "inquiries");
}

export function updateInquiries(token: string, items: InquiryItem[]): Promise<InquiryItem[]> {
    return updateOMSDocument(token, "inquiries", items);
}

export function deleteInquiries(token: string, ids: string[]) {
    return deleteOMSDocument(token, "inquiries", ids);
}

export function updateCurrentShipmentLog(token: string, items: CurrentShipmentLogItem[]): Promise<CurrentShipmentLogItem[]> {
    return updateOMSDocument(token, "current_shipment_log", items);
}

export function deleteCurrentShipmentLog(token: string, ids: string[]) {
    return deleteOMSDocument(token, "current_shipment_log", ids);
}

export function requestBackendKeepa(token: string, ids: string[], projection?: string) {
    return fetch(`${BASE_URL}/keepa/requestByAsin?firebase_token=${token}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            asins: ids,
            keepa_key: KeepaApiKey,
            projection: projection,
        }),
    }).then(handleError);
}

export function getOMSItemsByASIN(token: string, asinList: string[], limitToOne = true) {
    return fetch(`${BASE_URL}/prelisting/getByAsins?firebase_token=${token}&limitToOne=${limitToOne}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            asins: asinList,
        }),
    })
        .then(handleError)
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

export function getWholesaleStatistics(asinList: string[]) {
    return getIdToken()
        .then((token) =>
            fetch(`${BASE_URL}/wholesale/itemStatistics?firebase_token=${token}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    asins: asinList,
                }),
            })
        )
        .then(handleError)
        .then((data) => (DEVELOPMENT_RESPONSE ? [] : data.result));
}

export async function processInvoicePDF(
    token: string,
    file: File,
    items: {sku: string; name: string; price?: number}[]
): Promise<{invoice: Invoice; csv: string; id_matches: InvoiceIdMatch[]; call_id: string}> {
    // Verify file type
    if (file.type !== "application/pdf") {
        throw new Error("File must be a PDF");
    }

    // Convert file to base64
    const base64File = await fileToBase64(file);

    // Make request
    const response = await fetch(`${BASE_URL_V2}/invoices/process_base64?token=${token}`, {
        method: "POST",
        body: JSON.stringify({
            pdf: base64File,
            supplier_items: items,
        }),
        headers: {
            "Content-Type": "application/json",
        },
    }).then(handleError);

    return response as {invoice: Invoice; csv: string; id_matches: InvoiceIdMatch[]; call_id: string};
}

export async function saveInvoiceValidation(callId: string, data: {po_number: string; invoice_number: string; items: InvoiceSampleItem[]}) {
    const token = await getIdToken();
    return fetch(`${BASE_URL_V2}/invoices/save_validation_results?token=${token}&call_id=${callId}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        },
    }).then(handleError);
}
