import {DeleteFilled, LinkOutlined} from "@ant-design/icons";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {Button, Divider, InputRef, notification, Space, Switch, Tag} from "antd";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import {deleteSuppliers, updateSuppliers} from "../../../services/WholesaleService";
import {ACCEPTANCE_MODES, SUPPLIER_MANAGERS, SUPPLIER_USERS, Supplier, SupplierRenderType} from "../../../types/OmegaTypes";
import {makeColumnsEditable} from "../../utilities/OMSColumns";
import {validateSuppliers} from "../../utilities/TableDataValidation";
import {getBooleanFilter, getBoolRenderer, getDefaultFilterSorter} from "../../utilities/TableFilterSorters";
import {UnsavedChangesHandler} from "../../utilities/UnsavedChangesHandler";
import {EditableColumnType} from "../table/EditableCell";
import EditableTable from "../table/EditableTable";
import {useSuppliers} from "./dataHandlers";
import {GetExpandedFilter} from "../../utilities/ExpandedFilterDropdown";
import {CSVDownload} from "../../utilities/CSVDownload";
import dayjs from "dayjs";
import * as dataForge from "data-forge";
import {BRAND_DEFAULT_VALUES} from "../../dashboard/BrandValues";

const defaultItem: Supplier = {
    name: "",
    user: "",
    VendorType: "OUR BRANDS",
    ccAcceptance: "",
    mapFollow: "",
    bizMapFollow: "",
    mapNotify: "",
    paymentProcess: "",
    supplierNotes: {},
    connectedUsers: [],
    supplierBrands: [],
    date: undefined,
};

export const SupplierNamesBrowser: React.FC = () => {
    const {data: suppliers, isLoading} = useSuppliers();
    const [localSuppliers, setLocalSuppliers] = useState<SupplierRenderType[]>([]);
    const queryClient = useQueryClient();
    const {currentUser, userData} = useAuth();
    const [fetchTimestamp, setFetchTimestamp] = useState<Date>(new Date());
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<Date>(fetchTimestamp);
    const [editable, setEditable] = useState(false);
    const searchInputRef = useRef<InputRef>(null);

    const mutation = useMutation({
        mutationFn: async (suppliers: Supplier[]) => {
            await queryClient.cancelQueries({queryKey: ["supplier_names"]});
            const token = await currentUser!.getIdToken();

            if (!token) {
                throw new Error("Auth token not found");
            }

            const globalData = queryClient.getQueryData<Supplier[]>(["supplier_names"]);

            if (!globalData) {
                throw new Error("Global data was not parsed correctly");
            }
            const globalIds = globalData.map((item) => item._id);
            const localIds = suppliers.map((item) => item._id);
            const idsToDelete = _.difference(globalIds, localIds);

            // remove dataValues from push
            for (const supplier of suppliers) {
                for (const key of Object.keys(supplier.dataValues ?? BRAND_DEFAULT_VALUES)) {
                    delete supplier[key as keyof Supplier];
                }
            }

            const returnedSuppliers = await updateSuppliers(token, suppliers);
            await deleteSuppliers(token, idsToDelete.filter((id) => id !== undefined) as string[]);
            return returnedSuppliers;
        },
        onSuccess: (suppliers) => {
            queryClient.setQueryData(["supplier_names"], suppliers);
            setLastUpdateTimestamp(new Date());
            setFetchTimestamp(new Date());
            notification.success({
                message: "Updated successfully!",
            });
        },
        onError: (error) => {
            notification.error({
                message: "Upload error",
                // @ts-ignore
                description: error.message,
            });
        },
    });

    useEffect(() => {
        if (suppliers) {
            setLocalSuppliers(
                suppliers.map((item, i) => ({
                    ...item,
                    key: i,
                    index: i,
                    ...(item.dataValues ?? BRAND_DEFAULT_VALUES),
                }))
            );
        }
    }, [suppliers]);

    const handleSave = useCallback(
        (row: SupplierRenderType) => {
            if (!suppliers) return;

            const newData = [...localSuppliers];
            const index = newData.findIndex((item) => row.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            setLocalSuppliers(newData);
            setLastUpdateTimestamp(new Date());
        },
        [localSuppliers, suppliers]
    );

    const columns = useMemo<EditableColumnType<SupplierRenderType>[]>(() => {
        const supplierColumns: EditableColumnType<SupplierRenderType>[] = [
            {
                title: "Idx",
                dataIndex: "index",
                key: "index",
                width: "15px",
                ...getDefaultFilterSorter("index", searchInputRef),
            },
            {
                title: "Name",
                dataIndex: "name",
                key: "name",
                width: "150px",
                editable: true,
                ...getDefaultFilterSorter("name", searchInputRef),
                render: (text) => (
                    <div style={{width: "150px"}}>
                        {text}{" "}
                        <LinkOutlined
                            onClick={(e) => {
                                window.open(`/brand?selected_supplier=${text}&brand_info_tab=values&brand_tab=dashboard`);
                            }}
                        ></LinkOutlined>
                    </div>
                ),
            },
            {
                title: "Vendor Type",
                dataIndex: "VendorType",
                key: "VendorType",
                editable: true,
                inputType: "vendor types",
                width: "65px",
                ...getDefaultFilterSorter("VendorType", searchInputRef),
            },
            {
                title: "Lead Buyer",
                dataIndex: "brandManager",
                key: "brandManager",
                width: "100px",
                inputType: "supplier users",
                editable: false,
                ...GetExpandedFilter([...SUPPLIER_USERS, ...SUPPLIER_MANAGERS], "leadBuyer"),
                render: (users) => (
                    <Space direction="vertical" align="center" style={{width: "100%"}}>
                        {users
                            ? [users]?.map((user: string, idx: number) => (
                                  <Tag style={{marginRight: 0}} key={idx}>
                                      {user}
                                  </Tag>
                              ))
                            : []}
                    </Space>
                ),
            },
            {
                title: "Strategist",
                dataIndex: "brandStrategist",
                key: "brandStrategist",
                width: "100px",
                inputType: "supplier users",
                editable: false,
                ...GetExpandedFilter([...SUPPLIER_USERS], "brandStrategist"),
                render: (users) => (
                    <Space direction="vertical" align="center" style={{width: "100%"}}>
                        {users?.map((user: string, idx: number) => (
                            <Tag style={{marginRight: 0}} key={idx}>
                                {user}
                            </Tag>
                        )) ?? []}
                    </Space>
                ),
            },
            {
                title: "Accepts CC?",
                dataIndex: "ccAcceptance",
                key: "ccAcceptance",
                width: "70px",
                // inputType: 'yes/no',
                inputType: "select",
                inputOptions: {
                    selectableValues: ACCEPTANCE_MODES.slice(),
                },
                editable: true,
                ...GetExpandedFilter([...ACCEPTANCE_MODES], "ccAcceptance"),
            },
            {
                title: "Payment Process",
                dataIndex: "paymentProcess",
                key: "paymentProcess",
                width: "200px",
                editable: true,
                ...GetExpandedFilter([], "paymentProcess"),
            },
            {
                title: "MAP",
                dataIndex: "mapPolicyValue",
                key: "mapPolicyValue",
                width: "70px",
                ...getBoolRenderer(),
                ...getBooleanFilter("mapPolicyValue"),
            },
            {
                title: "MAP Strategy",
                dataIndex: "mapPolicyValueText",
                key: "mapPolicyValueText",
                width: "70px",
                ...GetExpandedFilter([], "mapPolicyValueText"),
            },
            {
                title: "Prep Delay",
                dataIndex: "prepDelay",
                key: "prepDelay",
                width: "70px",
                ...GetExpandedFilter([], "prepDelay"),
            },
            {
                title: "Terms",
                dataIndex: "paymentTerms",
                key: "paymentTerms",
                width: "70px",
                ...GetExpandedFilter([], "paymentTerms"),
            },
            {
                title: "Transactional",
                dataIndex: "growthPlan",
                key: "growthPlan",
                width: "70px",
                ...GetExpandedFilter([], "growthPlan"),
            },
            {
                title: "Connected users",
                dataIndex: "connectedUsers",
                key: "connectedUsers",
                width: "100px",
                inputType: "supplier users",
                editable: true,
                ...GetExpandedFilter([...SUPPLIER_USERS], "connectedUsers"),
                render: (users) => (
                    <Space direction="vertical" align="center" style={{width: "100%"}}>
                        {users?.map((user: string, idx: number) => (
                            <Tag style={{marginRight: 0}} key={idx}>
                                {user}
                            </Tag>
                        )) ?? []}
                    </Space>
                ),
            },
        ];

        if (editable) {
            supplierColumns.push({
                title: "Action",
                dataIndex: "",
                key: "x",
                render: (_, record) => (
                    <Button
                        disabled={!userData?.role.includes("ADMIN")}
                        danger
                        icon={<DeleteFilled />}
                        onClick={() => {
                            const dataCopy = [...localSuppliers];
                            const newData = dataCopy.filter((item) => item._id !== record._id);

                            setLastUpdateTimestamp(new Date());
                            setLocalSuppliers(
                                newData.map((item, i) => ({
                                    ...item,
                                    index: i,
                                }))
                            );
                        }}
                    />
                ),
                fixed: "right",
                width: "30px",
            });
        }

        return makeColumnsEditable(supplierColumns, editable ? handleSave : undefined);
    }, [handleSave, localSuppliers, editable, userData?.role]);

    const onSaveChanges = () => {
        if (validateSuppliers(localSuppliers).length === 0) {
            mutation.mutate(localSuppliers.map(({key, ...item}) => item));
        }
    };

    const addNewRow = () => {
        const newItem: Supplier = {
            ...defaultItem,
            date: new Date(),
        };
        const newData = [newItem, ...localSuppliers];
        setLocalSuppliers(newData.map((item, idx) => ({...item, key: idx, index: idx})));
        setLastUpdateTimestamp(new Date());
    };

    const tableTitle = () => (
        <Space direction="horizontal" style={{width: "100%", justifyContent: "end"}} split={<Divider type="vertical" />}>
            <UnsavedChangesHandler
                isSaved={lastUpdateTimestamp <= fetchTimestamp}
                disableWhenSaved={localSuppliers.length === 0}
                isLoading={mutation.isPending}
                onDiscardChanges={() => {
                    setLocalSuppliers(
                        (suppliers || []).map((item, i) => ({
                            ...item,
                            ...(item.dataValues ?? BRAND_DEFAULT_VALUES),
                            key: i,
                            index: i,
                        }))
                    );
                    setLastUpdateTimestamp(new Date());
                    setFetchTimestamp(new Date());
                }}
                onSaveChanges={onSaveChanges}
            />

            <Space>
                Editable: <Switch title="Editable" checked={editable} onChange={setEditable} />
            </Space>
            <CSVDownload
                collection={`Supplier_Names_Export_${dayjs().format("MM-DD-YY")}`}
                data={localSuppliers || []}
                dateColumns={["date"]}
                isLoading={!suppliers}
                parse={(data) => {
                    return new dataForge.DataFrame(data)
                        .transformSeries({
                            connectedUsers: (val: string[]) => (val && Array.isArray(val) ? val.join(",") : ""),
                            brandStrategist: (val: string[]) => (val && Array.isArray(val) ? val.join(",") : ""),
                        })
                        .subset(columns.map((col) => col.dataIndex as string))
                        .toArray();
                }}
            />
            <Button
                onClick={() => {
                    addNewRow();
                }}
            >
                Add row
            </Button>
        </Space>
    );

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <EditableTable<SupplierRenderType> title={tableTitle} tableData={localSuppliers} columns={columns} loading={isLoading} />
        </Space>
    );
};
